import Link from 'next/link'
import React from 'react'
if (typeof window !== 'undefined') {
  import('@dotlottie/player-component')
}
declare global {
  namespace JSX {
    interface IntrinsicElements {
      'dotlottie-player': any
    }
  }
}

const Lotti = (props: any) => {
  const bannerLink = (element: any) => {
    switch (element.linktype) {
      case 'EXTERNAL':
        return `${element.link.url}`
      case 'PRODUCT':
        if (element.product.pd_type == 'VARIANT') {
          return {
            pathname: '/[slug]/p',
            query: {
              slug: element.product.code,
            },
          }
        } else {
          return {
            pathname: `/s`,
            query: {
              categories_slug: `categories_slug:=[\`${element.product.code}\`]`,
            },
          }
        }
      case 'TAG':
        if (element.tag?.tagtype == 'PRODUCT') {
          return {
            pathname: `/s`,
            query: {
              categories: `tags:=[\`${element?.tag?.code}\`]`,
            },
          }
        } else {
          return {
            pathname: `/s`,
            query: {
              categories: `tags:=[\`${element?.tag?.code}\`]`,
            },
          }
        }

      case 'PAGE':
        if (element.page.pg_type == 'GENERAL') {
          return {
            pathname: '/[slug]/page',
            query: {
              type: 'group',
              slug: element.page.code,
            },
          }
        } else if (element.page.pg_type == 'CATEGORY') {
          return {
            pathname: '/[slug]/page',
            query: {
              type: 'category',
              slug: element.page.code,
            },
          }
        } else {
          return {
            pathname: '/[slug]/page',
            query: {
              type: 'brand',
              slug: element.page.code,
            },
          }
        }

      default:
        return `${element.slug}`
    }
  }
  const Lnk = (props: any) => {
    const { children } = props
    return props.linktype == 'NONE' ? (
      <>{children}</>
    ) : (
      <Link
        href={bannerLink(props)}
        passHref
        target={props.link?.target != 'undefined' && props.link?.target}
      >
        {children}
      </Link>
    )
  }
  const Lotti = (props) => {
    return (
      <dotlottie-player
        src={props?.lottieurl}
        autoplay={props?.mode[props?.deviceMode].autoplay}
        loop={props?.mode[props?.deviceMode].loop}
      />
    )
  }
  return (
    <Lnk {...props}>
      <Lotti {...props} />
    </Lnk>
  )
}
export default Lotti
