import { FC } from 'react'

type EyeProp = {
  strokeColor?: any
  className?: any
  strokeWidth?: any
}
export const Eye: FC<EyeProp | any> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${props.className ? props.className : 'h-5 w-5'}`}
      fill="none"
      viewBox="0 0 24 24"
      stroke={`${props.strokeColor ? props.strokeColor : 'currentColor'}`}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={props.strokeWidth ? props.strokeWidth : '2'}
        d="M12 15a3 3 0 100-6 3 3 0 000 6z"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={props.strokeWidth ? props.strokeWidth : '2'}
        d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z"
      />
    </svg>
  )
}
