export const fetchThumbnail = async (videoUrl) => {
  try {
    const video = document.createElement('video')
    video.src = videoUrl
    video.setAttribute('crossorigin', 'anonymous')

    video.currentTime = 1

    await new Promise((resolve) => {
      video.ontimeupdate = resolve
    })

    const canvas = document.createElement('canvas')
    canvas.width = video.videoWidth
    canvas.height = video.videoHeight
    const ctx = canvas.getContext('2d')

    ctx.drawImage(video, 0, 0, canvas.width, canvas.height)

    const thumbnail = await new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(URL.createObjectURL(blob))
      }, 'image/jpeg')
    })

    return thumbnail
  } catch (error) {
    console.error('Error fetching thumbnail:', error)
    return null // Return null in case of error
  }
}
