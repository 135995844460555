import Link from 'next/link'
import React, { useState, FC, useEffect } from 'react'
import style from '../page-navigation.module.scss'

const PageNavChild: FC<any> = (props) => {
  const [isChildHover, setIsChildHover] = useState(-1)

  const [pageNavChild, setPageNavChild] = useState([] as any)
  useEffect(() => {
    setPageNavChild(props.data.children)
  }, [props.data])

  return (
    <div className={style.child_nav}>
      {pageNavChild.map((ele: any, index: number) => (
        <div
          key={index}
          style={{
            backgroundColor:
              isChildHover === index
                ? `${props.dynamicStyle.colorHover.background}`
                : `${props.dynamicStyle.color.background}`,
            color:
              isChildHover === index
                ? `${props.dynamicStyle.colorHover.text}`
                : `${props.dynamicStyle.color.text}`,
            borderColor: props.dynamicStyle.isSeparator
              ? props.dynamicStyle.color.separator
              : 'transparent',
            borderBottomWidth: props.dynamicStyle.isSeparator ? 1 : 0,
          }}
          onMouseEnter={() =>
            setIsChildHover(isChildHover === index ? -1 : index)
          }
          onMouseLeave={() =>
            setIsChildHover(isChildHover === index ? -1 : index)
          }
        >
          <Link href={ele.url} target={ele.target}>
            {ele.title}
          </Link>
        </div>
      ))}
    </div>
  )
}

export default PageNavChild
