// @ts-ignore
// @ts-nocheck
import React, {
  FC,
  useEffect,
  useRef,
  useState,
  useMemo,
  useLayoutEffect,
} from 'react'
import Img from '~/components/ui/img/img'
import { PimHomeService } from '~/services/pim.service'
import BrandautoplayScrollWrapper from '../../Brandautoplay-scroll-wrapper/brandautoplay-scroll-wrapper'
import { GridWidthCalculator } from './layout-calc'
import Link from 'next/link'
import BrandCard from '../../cards/brand-card/brand-card'
import { useRouter } from 'next/router'
import style from './category.module.scss'

interface Datum {
  __v: number
  _id: string
  channel: string
  code: string
  createdby: string
  createddate: string
  isActive: boolean
  list: List[]
  modifiedby: string
  modifieddate: string
  name: Name2
  org_code?: any
  published: Published
}

interface Published {
  datetime: string
  status: string
  version: number
}

interface Name2 {
  all: string
  'en-IN': string
  'ta-IN': string
}

interface Name {
  'en-IN': string
  'ta-IN': string
}

interface Image {
  height: number
  isDynamic: boolean
  shape: string
  url: string
  width: number
}

interface List {
  _id: string
  code: string
  image: Image
  name: string
}

interface Image {
  height: number
  isDynamic: boolean
  shape: string
  url: string
  width: number
}

type HeadContentProps = {
  image: string
  title: string
}
type BrandContentProps = {
  image: string
  title: string
}

const Brandlist = (props) => {
  const [modedata, _setModeData] = useState(props.deviceMode)

  const mode = useMemo(() => {
    if (!!!props.mode) {
      return {
        colspan: 0,
        isScroll: false,
        home_sidebanner: '',
        coulmnadjust: '',
      }
    }
    return props.mode[modedata]
  }, [props, modedata])
  const router = useRouter()
  const [link, _setLink] = useState('')
  const [loader, _setLoader] = useState(false)
  const [_width, setWidth] = useState(1200)

  const wrapper = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const changeWidth = () => {
      let w = GridWidthCalculator(wrapper)
      setWidth(w)
    }
    changeWidth()
    window.onreset = () => changeWidth()
  }, [])

  const HeadContent: FC<HeadContentProps> = (props) => {
    return (
      <div
        className={`${
          props.mode[props.deviceMode].isHeader == true ? 'block' : 'hidden'
        }`}
      >
        <Link
          href={{
            pathname: '/[slug]/page',
            query: {
              type: 'brand',
              slug: link,
            },
          }}
          className="flex align-middle items-center justify-center mt-2 mb-2"
        >
          {!!props.mode[props.deviceMode].header?.image ? (
            <Img
              rWidth={{
                mobileweb: 50,
                web: 50,
              }}
              rHeight={{
                mobileweb: 50,
                web: 50,
              }}
              deviceType={props.deviceMode}
              src={
                !!props.mode[props.deviceMode].header?.image
                  ? props.mode[props.deviceMode].header?.image
                  : 'https://img.poorvika.com/common/Noimage.png'
              }
              alt={props?.mode[props.deviceMode].header?.image?.alt}
              loader={() =>
                !!props.mode[props.deviceMode].header?.image
                  ? props.mode[props.deviceMode].header?.image
                  : 'https://img.poorvika.com/common/Noimage.png'
              }
              blurDataURL={
                !!props.mode[props.deviceMode].header?.image
                  ? props.mode[props.deviceMode].header?.image
                  : 'https://img.poorvika.com/common/Noimage.png'
              }
              placeholder="blur"
            />
          ) : (
            ''
          )}
          <span
            className="pl-4 inline-block"
            style={{
              color: props?.mode[props.deviceMode].header?.style?.fontColor,
              fontSize: props?.mode[props.deviceMode].header?.style?.fontSize,
              fontWeight:
                props?.mode[props.deviceMode].header?.style?.fontStyle
                  ?.isBold && 'bold',
              fontStyle:
                props?.mode[props.deviceMode].header?.style?.fontStyle
                  ?.isItalic && 'italic',
              textDecoration:
                props?.mode[props.deviceMode].header?.style?.fontStyle
                  ?.isUnderLine && 'underline',
            }}
          >
            {props.mode[props.deviceMode].header?.title}
          </span>
        </Link>
      </div>
    )
  }

  const gridLayout = () => {
    if (props.items?.length >= 1 && props?.isActive == true) {
      switch (mode.layout) {
        case 'NONE':
          if (mode.isScroll == false) {
            return (
              <div
                className={`ml-5 ${
                  props.items.length < 14 && 'flex flex-wrap'
                }`}
              >
                {props.items?.map((e: any, id: number) => (
                  <div
                    key={id}
                    className={`inline-block align-top mb-4 p-2 text-center ${
                      props.items.length < 14 && style.new_brand
                    }`}
                  >
                    <Link
                      href={
                        e?.pageData?.length > 0
                          ? e?.pageData[0]?.isActive == false
                            ? `/s?brand_name=brand_name:=[\`${e?.name}\`]`
                            : {
                                pathname: `/[slug]/page`,
                                query: {
                                  type: 'brand',
                                  slug: e?.pageData[0]?.code,
                                },
                              }
                          : `/s?brand_name=brand_name:=[\`${e?.name}\`]`
                      }
                      rel={'canonical'}
                    >
                      <Img
                        rWidth={{
                          mobileweb: 50,
                          web: 85,
                        }}
                        rHeight={{
                          mobileweb: 50,
                          web: 85,
                        }}
                        deviceType={props.deviceMode}
                        src={
                          !!e.image?.url
                            ? e.image && e.image?.url
                            : 'https://img.poorvika.com/common/Noimage.png'
                        }
                        className={` ${style.category_image} ${
                          style[props.mode[modedata].style]
                        } m-auto`}
                        loader={() =>
                          !!e.image?.url
                            ? e.image && e.image?.url
                            : 'https://img.poorvika.com/common/Noimage.png'
                        }
                        blurDataURL={
                          !!e.image?.url
                            ? e.image && e.image?.url
                            : 'https://img.poorvika.com/common/Noimage.png'
                        }
                        placeholder="blur"
                        alt={e?.image?.alt}
                      />

                      <span className="text-center capitalize text-base font-medium p-2">
                        {e.name}
                      </span>
                    </Link>
                  </div>
                ))}
              </div>
            )
          } else {
            return (
              <BrandautoplayScrollWrapper
                headerHtml={props.mode[modedata].style}
              >
                {props.items?.map((res: any, i: number) => (
                  <BrandCard
                    {...res}
                    key={i}
                    headerHtml={props.mode[modedata].style}
                    mode={props.deviceMode}
                  />
                ))}
              </BrandautoplayScrollWrapper>
            )
          }

        case 'MEDIUM':
          if (mode.isScroll == true) {
            return <BrandContent {...props} />
          } else {
            return (
              <div className={`grid grid-cols-2 gap-1`}>
                {props.items?.slice(0, 4).map((item1, _i: number) => (
                  <div key={_i} className={`mb-5  m-1 rounded-2xl flex-1 ...`}>
                    <Link
                      href={
                        item1?.pageData?.length > 0
                          ? item1?.pageData[0]?.isActive == false
                            ? `/s?brand_name=brand_name:=[\`${item1?.name}\`]`
                            : {
                                pathname: `/[slug]/page`,
                                query: {
                                  type: 'brand',
                                  slug: item1?.pageData[0]?.code,
                                },
                              }
                          : `/s?brand_name=brand_name:=[\`${item1?.name}\`]`
                      }
                      rel="canonical"
                      className={`${style.brand_link}`}
                    >
                      <Img
                        rWidth={{
                          mobileweb: 50,
                          web: 85,
                        }}
                        rHeight={{
                          mobileweb: 50,
                          web: 85,
                        }}
                        deviceType={props.deviceMode}
                        src={
                          !!item1.image?.url
                            ? item1.image && item1.image?.url
                            : 'https://img.poorvika.com/common/Noimage.png'
                        }
                        className={`${style[mode.style]}  m-auto`}
                        alt={item1?.image?.alt}
                        loader={() =>
                          !!item1.image?.url
                            ? item1.image && item1.image?.url
                            : 'https://img.poorvika.com/common/Noimage.png'
                        }
                        blurDataURL={
                          !!item1.image?.url
                            ? item1.image && item1.image?.url
                            : 'https://img.poorvika.com/common/Noimage.png'
                        }
                        placeholder="blur"
                      />
                      <h4
                        className={`text-center capitalize text-base font-medium p-2`}
                      >
                        {item1.code}
                      </h4>
                    </Link>
                  </div>
                ))}
              </div>
            )
          }

        case 'SMALL':
          if (mode.isScroll == true) {
            return <BrandContent {...props} />
          } else {
            return (
              <div className={`grid grid-cols-3 gap-1`}>
                {props.items?.slice(0, 6).map((item1, _i: number) => (
                  <div key={_i} className={`mb-5  m-1 rounded-2xl flex-1 ...`}>
                    <Link
                      href={
                        item1?.pageData?.length > 0
                          ? item1?.pageData[0]?.isActive == false
                            ? `/s?brand_name=brand_name:=[\`${item1?.name}\`]`
                            : {
                                pathname: `/[slug]/page`,
                                query: {
                                  type: 'brand',
                                  slug: item1?.pageData[0]?.code,
                                },
                              }
                          : `/s?brand_name=brand_name:=[\`${item1?.name}\`]`
                      }
                      rel="canonical"
                      // className="flex align-middle items-center"
                      className={`${style.brand_link}`}
                    >
                      <Img
                        rWidth={{
                          mobileweb: 50,
                          web: 85,
                        }}
                        rHeight={{
                          mobileweb: 50,
                          web: 85,
                        }}
                        deviceType={props.deviceMode}
                        src={
                          !!item1.image?.url
                            ? item1.image && item1.image?.url
                            : 'https://img.poorvika.com/common/Noimage.png'
                        }
                        className={` ${style[mode.style]} m-auto`}
                        loader={() =>
                          !!item1.image?.url
                            ? item1.image && item1.image?.url
                            : 'https://img.poorvika.com/common/Noimage.png'
                        }
                        blurDataURL={
                          !!item1.image?.url
                            ? item1.image && item1.image?.url
                            : 'https://img.poorvika.com/common/Noimage.png'
                        }
                        placeholder="blur"
                        alt={item1?.image?.alt}
                      />
                      <h4
                        className={`text-center capitalize text-base font-medium p-2`}
                      >
                        {item1.code}
                      </h4>
                    </Link>
                  </div>
                ))}
              </div>
            )
          }
        case 'MEDIUM_THREE':
          if (mode.isScroll == true) {
            return <BrandContent {...props} />
          } else {
            return (
              <>
                <div>
                  <div className={`grid grid-cols-1 gap-1`}>
                    {props.items.slice(0, 1).map((item, _i: number) => (
                      <div
                        key={_i}
                        className={`mb-5  m-1 rounded-2xl flex-1 ...`}
                      >
                        <Link
                          href={
                            item?.pageData?.length > 0
                              ? item?.pageData[0]?.isActive == false
                                ? `/s?brand_name=brand_name:=[\`${item?.name}\`]`
                                : {
                                    pathname: `/[slug]/page`,
                                    query: {
                                      type: 'brand',
                                      slug: item?.pageData[0]?.code,
                                    },
                                  }
                              : `/s?brand_name=brand_name:=[\`${item.name}\`]`
                          }
                          rel="canonical"
                          className={`${style.brand_link}`}
                        >
                          <Img
                            rWidth={{
                              mobileweb: 50,
                              web: 85,
                            }}
                            rHeight={{
                              mobileweb: 50,
                              web: 85,
                            }}
                            deviceType={props.deviceMode}
                            src={
                              !!item.image?.url
                                ? item.image && item.image?.url
                                : 'https://img.poorvika.com/common/Noimage.png'
                            }
                            className={`m-auto  ${style[mode.style]} ${
                              mode.layout == 'MEDIUM_THREE' ? 'block' : 'hidden'
                            }`}
                            loader={() =>
                              !!item.image?.url
                                ? item.image && item.image?.url
                                : 'https://img.poorvika.com/common/Noimage.png'
                            }
                            blurDataURL={
                              !!item.image?.url
                                ? item.image && item.image?.url
                                : 'https://img.poorvika.com/common/Noimage.png'
                            }
                            placeholder="blur"
                            alt={item?.image?.alt}
                          />
                          <h4
                            className={`text-center capitalize text-base font-medium p-2 `}
                          >
                            {item.code}
                          </h4>
                        </Link>
                      </div>
                    ))}
                  </div>
                  <div
                    className={`grid grid-cols-2 gap-1 ${
                      mode.layout == 'MEDIUM_THREE' ? 'block' : 'hidden'
                    }`}
                  >
                    {props.items?.slice(1, 3).map((item, _i: number) => (
                      <div
                        key={_i}
                        className={`mb-5  m-1 rounded-2xl flex-1 ...`}
                      >
                        <Link
                          href={
                            item?.pageData?.length > 0
                              ? item?.pageData[0]?.isActive == false
                                ? `/s?brand_name=brand_name:=[\`${item?.name}\`]`
                                : {
                                    pathname: `/[slug]/page`,
                                    query: {
                                      type: 'brand',
                                      slug: item?.pageData[0]?.code,
                                    },
                                  }
                              : `/s?brand_name=brand_name:=[\`${item?.name}\`]`
                          }
                          rel="canonical"
                          className={`${style.brand_link}`}
                        >
                          <Img
                            rWidth={{
                              mobileweb: 50,
                              web: 85,
                            }}
                            rHeight={{
                              mobileweb: 50,
                              web: 85,
                            }}
                            deviceType={props.deviceMode}
                            src={
                              !!item.image?.url
                                ? item.image && item.image?.url
                                : 'https://img.poorvika.com/common/Noimage.png'
                            }
                            className={`${style[mode.style]} m-auto`}
                            loader={() =>
                              !!item.image?.url
                                ? item.image && item.image?.url
                                : 'https://img.poorvika.com/common/Noimage.png'
                            }
                            blurDataURL={
                              !!item.image?.url
                                ? item.image && item.image?.url
                                : 'https://img.poorvika.com/common/Noimage.png'
                            }
                            placeholder="blur"
                            alt={item?.image?.alt}
                          />
                          <h4
                            className={`text-center capitalize text-base font-medium p-2`}
                          >
                            {item.code}
                          </h4>
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )
          }
        default:
          return loader ? '' : <div></div>
      }
    }
  }

  const BrandContent: FC<BrandContentProps> = (props) => (
    <div
      className={` ${style.category_scroll} pt-4 pb-4 overflow-x-scroll overscroll-y-none whitespace-nowrap text-center ${style.overflow_menu}`}
    >
      {props.items?.map((e: any, id: number) => (
        <div key={id} className="inline-block w-1/5 align-top m-1">
          <Link
            href={
              e?.pageData?.length > 0
                ? e?.pageData[0]?.isActive == false
                  ? `/s?brand_name=brand_name:=[\`${e?.name}\`]`
                  : {
                      pathname: `/[slug]/page`,
                      query: {
                        type: 'brand',
                        slug: e?.pageData[0]?.code,
                      },
                    }
                : `/s?brand_name=brand_name:=[\`${e?.name}\`]`
            }
            rel={'canonical'}
            className={`${style.brand_link}`}
          >
            <Img
              rWidth={{
                mobileweb: 50,
                web: 85,
              }}
              rHeight={{
                mobileweb: 50,
                web: 85,
              }}
              deviceType={props.deviceMode}
              src={
                !!e.image?.url
                  ? e.image && e.image?.url
                  : 'https://img.poorvika.com/common/Noimage.png'
              }
              className={` ${style.category_imagenone} m-auto`}
              loader={() =>
                !!e.image?.url
                  ? e.image && e.image?.url
                  : 'https://img.poorvika.com/common/Noimage.png'
              }
              blurDataURL={
                !!e.image?.url
                  ? e.image && e.image?.url
                  : 'https://img.poorvika.com/common/Noimage.png'
              }
              placeholder="blur"
              alt={e?.image?.alt}
              style={{ margin: 'auto !important' }}
            />
            <span className="text-center capitalize text-base font-medium p-2">
              {e.name}
            </span>
          </Link>
        </div>
      ))}
    </div>
  )
  return (
    <div ref={wrapper} className={props.className}>
      <HeadContent {...props} />
      {gridLayout()}
    </div>
  )
}
export default Brandlist
