import Link from 'next/dist/client/link'
import { FC } from 'react'
import Img from '../../img/img'
import style from './brand-card.module.scss'

export const BrandCard: FC<any> = (props) => {
  return (
    <div>
      <Link
        href={
          props?.pageData?.length > 0
            ? props?.pageData[0]?.isActive == false
              ? `/s?brand_name=brand_name:=[\`${props.name}\`]`
              : {
                  pathname: `/[slug]/page`,
                  query: {
                    type: 'brand',
                    slug: props?.pageData[0]?.code,
                  },
                }
            : `/s?brand_name=brand_name:=[\`${props.name}\`]`
        }
      >
        {props.headerHtml == 'none' ? (
          <Img
            rWidth={{
              mobileweb: 50,
              web: 70,
            }}
            rHeight={{
              mobileweb: 50,
              web: 70,
            }}
            deviceType={props.mode}
            className={`${
              props.headerHtml == 'none' ? 'p-1' : style.brand_card
            }`}
            src={
              props.image?.url
                ? props.image?.url
                : 'https://img.poorvika.com/common/Noimage.png'
            }
            loader={() =>
              props.image?.url
                ? props.image?.url
                : 'https://img.poorvika.com/common/Noimage.png'
            }
            blurDataURL={
              props.image?.url
                ? props.image?.url
                : 'https://img.poorvika.com/common/Noimage.png'
            }
            alt={props?.image?.alt}
            placeholder="blur"
          />
        ) : props.headerHtml == 'style1' ? (
          <div className={style.brand_card}>
            <Img
              rWidth={{
                mobileweb: 50,
                web: 85,
              }}
              rHeight={{
                mobileweb: 50,
                web: 85,
              }}
              deviceType={props.mode}
              src={
                props.image?.url
                  ? props.image?.url
                  : 'https://img.poorvika.com/common/Noimage.png'
              }
              loader={() =>
                props.image?.url
                  ? props.image?.url
                  : 'https://img.poorvika.com/common/Noimage.png'
              }
              blurDataURL={
                props.image?.url
                  ? props.image?.url
                  : 'https://img.poorvika.com/common/Noimage.png'
              }
              alt={props?.image?.alt}
              placeholder="blur"
            />
          </div>
        ) : (
          <Img
            rWidth={{
              mobileweb: 50,
              web: 70,
            }}
            rHeight={{
              mobileweb: 50,
              web: 70,
            }}
            className={`${
              props.headerHtml == 'none' ? 'p-1' : style.brand_card
            }`}
            deviceType={props.mode}
            src={
              props.image?.url
                ? props.image?.url
                : 'https://img.poorvika.com/common/Noimage.png'
            }
            loader={() =>
              props.image?.url
                ? props.image?.url
                : 'https://img.poorvika.com/common/Noimage.png'
            }
            blurDataURL={
              props.image?.url
                ? props.image?.url
                : 'https://img.poorvika.com/common/Noimage.png'
            }
            alt={props?.image?.alt}
            placeholder="blur"
          />
        )}
      </Link>
    </div>
  )
}
export default BrandCard
