import React, { FC } from 'react'
import style from '~/styles/home.module.scss'
const Row: FC<any> = ({ props: rowProps, children, deviceMode }: any) => {
  return (
    <div
      className={` 
      ${
        rowProps?.mode[deviceMode]?.isScroll == true
          ? style.box_data_12
          : rowProps?.mode[deviceMode]?.flexView == true
          ? style.box_data_flex
          : `mt-1 mb-4 grid grid-cols-12 sm:grid-cols-12 md:grid-cols-12 lg:grid-cols-12
      } gap-3  overflow-y-${rowProps?.overflowH ? 'auto' : 'hidden'} `
      }
      ${rowProps?.mode[deviceMode]?.isVisible == false ? 'hidden' : ''}
     
`}
      style={{
        backgroundColor: rowProps?.bgColor,
        justifyContent: rowProps?.justify,
        alignItems: rowProps?.align,
        backgroundImage: `url(${rowProps?.bgImage?.url})`,
        minHeight: `${!rowProps?.bgImage?.url ? '' : rowProps?.minHeight}`,
        marginTop: rowProps?.mode[deviceMode]?.top,
        marginBottom: rowProps?.mode[deviceMode]?.bottom,
      }}
    >
      {children}
    </div>
  )
}
export default Row
