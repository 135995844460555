import React, { useEffect, useState } from 'react'
import Link from 'next/dist/client/link'
import { FC } from 'react'
import Img from '~/components/ui/img/img'
type HeadContentProps = {
  image: string
  title: string
}

const HeadContent: FC<HeadContentProps> = (props) => (
  <div>
    <div className="flex align-middle items-center">
      {!!props.image ? (
        <img
          height={50}
          width={50}
          src={props.image}
          alt="image"
          // loader={() => props.image}
          // blurDataURL={props.image}
          placeholder="blur"
        />
      ) : (
        ''
      )}
      <span className="font-semibold text-black text-lg pl-4 inline-block">
        {props.title}
      </span>
    </div>
  </div>
)

export const ProductList: FC<any> = (props) => {
  return (
    <div>
      <HeadContent {...props.data.header} />
    </div>
  )
}
