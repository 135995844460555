import router from 'next/router'
import { FC, useEffect, useState } from 'react'
import style from './video-card.module.scss'
import { fetchThumbnail } from '~/util/video-image'

/**
 * this components is used to display youtube video related to the product
 * @category Components
 * @subcategory VideoCard
 * @param {VideoCardProps} props object we get from hitting videos api
 * @returns {JSX.Element} JSX valu
 */
export const VideoCard: FC<any> = (props) => {
  const OnBackClick = () => {
    router.back()
  }
  const [thumbnailUrl, setThumbnailUrl] = useState('' as any)

  useEffect(() => {
    const fetchThumbnailUrl = async () => {
      if (props?.attr?.playerType === 'Video') {
        const url = await fetchThumbnail(props?.attr?.url)

        setThumbnailUrl(url)
      }
    }
    fetchThumbnailUrl()
  }, [])
  // useEffect(() => {
  //   window.addEventListener('keyup', (e) => {
  //     if (e.code == 'Escape') {
  //       OnBackClick()
  //     }
  //   })
  //   return () => {
  //     window.removeEventListener('keyup', (_e) => {})
  //   }
  // }, [])
  return (
    <div className={`${props.title === '' ? ' p-1 mb-2' : style.card}`}>
      <div
        className={`${style.card__video}`}
        style={{ height: props.mode === 'mobileweb' ? '100%' : 'auto' }}
      >
        <div
          className={`${style.card__video__thumbnail} `}
          style={{
            backgroundImage: `url(${
              props?.attr?.playerType === 'Video'
                ? thumbnailUrl
                : props?.attr?.img
            })`,
            height: `${props.height === true ? '428px' : ''}`,
          }}
        />

        <img
          onClick={() => props?.play()}
          src="https://img.poorvika.com//play_video.png"
          // loader={() => `https://img.poorvika.com//play_video.png`}
          // blurDataURL={`https://img.poorvika.com//play_video.png}`}
          placeholder="blur"
          alt="play video"
        />
      </div>
      <div
        className={`${props.title === '' ? 'hidden' : style.card__description}`}
      >
        <div className="flex items-center space-x-4">
          {props.thumbnail?.url != null && (
            <div className="flex-shrink-0">
              <img
                className="w-12 h-12 rounded-md"
                src={props.thumbnail.url}
                alt={props.thumbnail.url}
              ></img>
            </div>
          )}
          {props.title != '' && (
            <div className="flex-1 min-w-0">
              <p className="text-sm font-medium text-gray-900 truncate dark:text-white whitespace-pre-wrap">
                {props.title}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default VideoCard
