import Link from 'next/link'
import React, { FC, useEffect, useState } from 'react'
import { IsMobile, PriceFormat } from '~/util'
import Img from '../../img/img'
import style from './productfourteen.module.scss'
import { mrpPrice, sellingPrice } from '~/util/price'

type ProductCardData = {
  props: any
  data: any
  styles: string
  nobrorder: any
  mode?: string
  imgHeight?: any
  imgWidth?: any
}

const ProductFourteen: FC<ProductCardData> = (props) => {
  const [target, setTarget] = useState('_blank')
  useEffect(() => {
    if (window) {
      if (window.matchMedia('(display-mode: standalone)').matches) {
        setTarget('_self')
      }
      if (IsMobile()) {
        setTarget('_self')
      }
    }
  }, [])
  const link = {
    pathname: '/[slug]/p',
    query: {
      slug: props.data?.slug,
    },
  }
  let sellingprice = sellingPrice(props?.data?.prices[0]?.sp)
  let mrpprice = mrpPrice(props?.data?.mrp[0]?.price)
  return (
    <div className={`${style.card}`}>
      <Link href={link} passHref target={target}>
        <Img
          rWidth={{
            mobileweb: 140,
            web: 160,
          }}
          rHeight={{
            mobileweb: 140,
            web: 160,
          }}
          deviceType={props.props.mode}
          placeholder="blur"
          className={`${style.image}`}
          src={`${props.data.thumb ? props.data.thumb : props.data.image}`}
          alt={props?.data?.image?.alt}
          // layout="fill"
          // objectFit="contain"
          loader={() =>
            `${props.data.thumb ? props.data.thumb : props.data.image}`
          }
          blurDataURL={`${
            props.data.thumb ? props.data.thumb : props.data.image
          }`}
        />
        <div className={style.card__description}>
          <h3>{props.data.brand}</h3>
          <h4>{props.data.name}</h4>
          <div className={style.price}>
            <p>
              {sellingprice?.length != 0
                ? PriceFormat(sellingprice[0]?.price)
                : PriceFormat(mrpprice[0]?.price)}
            </p>
            {/* {Number(props.data.special_price) !== 0 && ( */}
            <div className={`${style.savings}`}>
              {Math.round(
                100 -
                  (Number(sellingprice[0]?.price) /
                    Number(mrpprice[0]?.price)) *
                    100
              ) > 0 && (
                <span className={style.offer}>
                  {Math.round(
                    100 -
                      (Number(sellingprice[0]?.price) /
                        Number(mrpprice[0]?.price)) *
                        100
                  )}
                  % OFF
                </span>
              )}
            </div>
            {/* )} */}
          </div>
        </div>
      </Link>
    </div>
  )
}

export default ProductFourteen
