// @ts-ignore
// @ts-nocheck
import React, { useRef, useEffect, useState } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Link from 'next/link'
import style from './../../../../styles/pim.module.scss'
import CountdownTimer from './counter/countDown'
import Img from '../../img/img'

function MoveNext(props: any) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        textAlign: 'center',
        background: '#FFFFFF',
        zIndex: '1',
        width: '30px',
        height: '80px',
        lineHeight: '100px',
        position: 'absolute',
        borderRadius: '5px',
        right: '0px',
      }}
      onClick={onClick}
    />
  )
}

function MovePrev(props: any) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        textAlign: 'center',
        background: '#FFFFFF',
        zIndex: '1',
        width: '30px',
        height: '80px',
        lineHeight: '100px',
        position: 'absolute',
        borderRadius: '5px',
        left: '0px',
      }}
      onClick={onClick}
    />
  )
}

const Carosuel = ({
  speed,
  autoplay,
  pauseOnHover,
  pagingDotVisible,
  sliderButtonVisible,
  vertical,
  slides: Slideprops,
  autoplayInterval,
  deviceMode,
  mode,
  carosuel,
}: any) => {
  const sliderProps = {
    infinite: true,
    speed: !!speed ? Number(speed) : Number(1000),
    slidesToShow: mode && mode[deviceMode].slidesToShow,
    arrows: true,
    dots: pagingDotVisible,
    autoplay: autoplay,
    pauseOnHover: pauseOnHover,
    slidesToScroll: mode && mode[deviceMode].slidesToShow,
    initialSlide: 0,
    autoplaySpeed: Number(autoplayInterval),
    className: vertical ? 'verticalswap' : '',
    vertical: vertical,
    verticalSwiping: vertical,
    nextArrow: sliderButtonVisible ? <MoveNext /> : <></>,
    prevArrow: sliderButtonVisible ? <MovePrev /> : <></>,
  }

  const bannerLink = (element: any) => {
    switch (element.linktype) {
      case 'EXTERNAL':
        return `${element.comp.link.url}`
      case 'PRODUCT':
        if (element.product?.pd_type == 'VARIANT') {
          return {
            pathname: '/[slug]/p',
            query: {
              slug: element.comp.product?.code,
            },
          }
        } else {
          return {
            pathname: `/s`,
            query: {
              categories_slug: `categories_slug:=[\`${element?.comp.product?.code}\`]`,
            },
          }
        }
      case 'TAG':
        if (element.tag?.tagtype == 'PRODUCT') {
          return {
            pathname: `/s`,
            query: {
              categories: `tags:=[\`${element?.tag?.code}\`]`,
            },
          }
        } else {
          return {
            pathname: `/s`,
            query: {
              categories: `tags:=[\`${element?.tag?.code}\`]`,
            },
          }
        }

      case 'PAGE':
        if (element.comp.page.pg_type == 'GENERAL') {
          return {
            pathname: '/[slug]/page',
            query: {
              type: 'group',
              slug: element.comp.page.code,
            },
          }
        } else if (element.comp.page.pg_type == 'CATEGORY') {
          return {
            pathname: '/[slug]/page',
            query: {
              type: 'category',
              slug: element.comp.page.code,
            },
          }
        } else {
          return {
            pathname: '/[slug]/page',
            query: {
              type: 'brand',
              slug: element.comp.page.code,
            },
          }
        }

      default:
        return `${element.slug}`
    }
  }
  const Lnk = (props: any) => {
    const { children } = props
    return props.linktype == 'NONE' ? (
      <>{children}</>
    ) : (
      <Link
        href={bannerLink(props)}
        passHref
        target={props.comp.link?.target}
        className={props.className}
      >
        {children}
      </Link>
    )
  }
  const childRef = useRef(null)
  const [imageHeight, setImageHeight] = useState(0)

  useEffect(() => {
    if (childRef.current) {
      setImageHeight(
        [...childRef.current?.querySelectorAll('img')][0]?.clientHeight
      )
    }
  }, [childRef.current])
  const Images = (props: any) => {
    return (
      <div ref={childRef}>
        <Img
          rWidth={{
            mobileweb: props.image?.url == undefined ? 772 : props.image?.width,
            web: props.image?.url == undefined ? 1900 : props.image?.width,
          }}
          rHeight={{
            mobileweb:
              props.image?.url == undefined ? 442 : props.image?.height,
            web: props.image?.url == undefined ? 600 : props.image?.height,
          }}
          deviceType={deviceMode}
          src={`${props.image?.url && props.image?.url}`}
          alt={props?.image?.alt}
          loader={() => `${props.image?.url && props.image?.url}`}
          blurDataURL={`${props.image?.url && props.image?.url}`}
          style={{ height: '100%', width: '100%' }}
          //priority
          //loading="lazy"
        />
      </div>
    )
  }

  const CarouselVideo = ({ playerType, videoID, url, controls }: any) => {
    return (
      <>
        {playerType === 'Video' ? (
          <video
            className={style.home_iframe}
            src={url}
            controls="true"
            style={{ width: '100%' }}
          />
        ) : (
          <iframe
            src={
              playerType === 'Youtube'
                ? 'https://www.youtube.com/embed/' + videoID
                : playerType === 'Vimeo'
                ? 'https://player.vimeo.com/video/' + videoID
                : 'https://www.dailymotion.com/embed/video/' + videoID
            }
            title="Video player"
            allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            className={`${style.carosuel_videos}`}
            height={imageHeight ? imageHeight : 300}
          ></iframe>
        )}
      </>
    )
  }

  return (
    <>
      <Slider {...sliderProps} aria-hidden="false">
        {carosuel?.map((slidedata, i: number) => (
          <div key={i}>
            {slidedata.sl_type == 'Image' ? (
              <div style={{ position: 'relative' }}>
                <Lnk {...slidedata} key={i}>
                  <Images key={i} {...slidedata} />
                </Lnk>
                {slidedata.timer?.isVisible && (
                  <>
                    <div className={style.timmerBackground} key={i}>
                      <CountdownTimer
                        targetDate={new Date(slidedata.timer.dateandtime)}
                        data={slidedata.timer.styles.timercolor}
                      />
                    </div>
                  </>
                )}
              </div>
            ) : (
              <div>
                <CarouselVideo
                  key={i}
                  className={`mt-4 mb-4`}
                  {...slidedata.video}
                />
              </div>
            )}
          </div>
        ))}
      </Slider>
    </>
  )
}
export default Carosuel
