import React, { FC } from 'react'
import { useCountdown } from './Counter'
import style from './counter.module.scss'
type DateProps = {
  value: any
  type: any
  isDanger: any
  data?: any
}
type DaysProps = {
  days: any
  hours: any
  minutes: any
  seconds: any
  data: any
}
type TargetTime = {
  targetDate: any
  data: any
}

const ExpiredNotice = () => {
  return <></>
}
const ShowCounter: FC<DaysProps> = (props) => {
  return (
    <div
      className={style.showcounter}
      style={{ background: `${props.data.color.background}` }}
    >
      <a
        href=""
        target="_blank"
        rel="noopener noreferrer"
        className={style.countdownlink}
      >
        <DateTimeDisplay
          value={props.days}
          type={'Days'}
          isDanger={false}
          data={props.data}
        />
        {/* <p>:</p> */}
        <DateTimeDisplay
          value={props.hours}
          type={'Hours'}
          isDanger={false}
          data={props.data}
        />
        {/* <p>:</p> */}
        <DateTimeDisplay
          value={props.minutes}
          type={'Mins'}
          isDanger={false}
          data={props.data}
        />
        {/* <p>:</p> */}
        <DateTimeDisplay
          value={props.seconds}
          type={'Seconds'}
          isDanger={false}
          data={props.data}
        />
      </a>
    </div>
  )
}
const DateTimeDisplay: FC<DateProps> = (props) => {
  return (
    <div className={props.isDanger ? style.danger : style.countdown}>
      <div className={style.rounded}>
        <p className={style.counthour}>{props.value}</p>
        <p className={style.countvalue}>{props.type}</p>
      </div>
    </div>
  )
}

const CountdownTimer: FC<TargetTime> = ({ targetDate, data }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate)

  if (days + hours + minutes + seconds <= 0) {
    return <ExpiredNotice />
  } else {
    return (
      <div>
        <ShowCounter
          days={days}
          hours={hours}
          minutes={minutes}
          seconds={seconds}
          data={data}
        />
      </div>
    )
  }
}
export default CountdownTimer
