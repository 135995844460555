import React, { FC, useEffect, useState } from 'react'
import { IsMobile, PriceFormat } from '~/util'
import style from './productseven.module.scss'
import Link from 'next/link'
import Img from '../../img/img'
type ProductCardData = {
  props: any
  data: any
  styles: string
  nobrorder: any
  mode?: string
  imgHeight?: any
  imgWidth?: string
}

const ProductSeven: FC<ProductCardData> = (props) => {
  const [target, setTarget] = useState('_blank')
  useEffect(() => {
    if (window) {
      if (window.matchMedia('(display-mode: standalone)').matches) {
        setTarget('_self')
      }
      if (IsMobile()) {
        setTarget('_self')
      }
    }
  }, [])
  const link = {
    pathname: '/[slug]/p',
    query: {
      slug: props.data?.slug,
    },
  }
  return (
    <div className={style.shadow}>
      <div className={`${style.width} `}>
        <Img
          rWidth={{
            mobileweb: 300,
            web: 450,
          }}
          rHeight={{
            mobileweb: 300,
            web: 450,
          }}
          deviceType={props.props.mode}
          placeholder="blur"
          className={`${style.image}`}
          src={`${props.data.thumb ? props.data.thumb : props.data.image}`}
          alt={props?.data?.image?.alt}
          // layout="fill"
          // objectFit="contain"
          loader={() =>
            `${props.data.thumb ? props.data.thumb : props.data.image}`
          }
          blurDataURL={`${
            props.data.thumb ? props.data.thumb : props.data.image
          }`}
        />
      </div>
      <div>
        <div className={style.card__description}>
          <Link href={link} target={target}>
            <b className={` break-normal ... text-sm truncate`}>
              {props.data.name}
            </b>

            <div className={style.storage} />
            <div className={`flex items-center py-2 `}>
              {props.styles == 'style9' ? (
                <span
                  className={`${
                    Number(props.data.price) > Number(props.data.special_price)
                      ? ' '
                      : 'h-4'
                  }`}
                >
                  {props.data.discount > 0 && (
                    <span
                      style={{
                        fontSize: '10px',
                        color: '#FB6C00',
                      }}
                    >
                      {props.data.discount + '% OFF'}
                    </span>
                  )}
                </span>
              ) : (
                <p className={`text whitespace-nowrap text-xs `}>
                  {Number(props.data.price) > Number(props.data.special_price)
                    ? PriceFormat(Number(props.data.special_price))
                    : PriceFormat(Number(props.data.price))}
                </p>
              )}
              <span style={{ flex: 1 }} />
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default ProductSeven
