import Link from 'next/link'
import React, { FC, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { IsMobile, Logger, PriceFormat } from '~/util'
import { FavouriteButton } from '../../favourite-button/favourite-button'
import Star from '../../icons/star'
import Img from '../../img/img'
import style from './producttwo.module.scss'
import router from 'next/router'
import { useMounted } from '~/util/hooks'
import { DetailStore } from '~/stores/detail'
import { CartState } from '~/stores/cart'
import { CartBtn } from '~/components/pages/cart/cart-btn/cart-btn'
import { AuthState } from '~/stores/auth'
import { mrpPrice, sellingPrice } from '~/util/price'

type ProductCardData = {
  props: any
  data: any
  styles: string
  nobrorder: any
  mode?: string
  imgHeight?: any
  imgWidth?: any
}

const ProductTwo: FC<ProductCardData> = (props) => {
  const [target, setTarget] = useState('_blank')
  useEffect(() => {
    if (window) {
      if (window.matchMedia('(display-mode: standalone)').matches) {
        setTarget('_self')
      }
      if (IsMobile()) {
        setTarget('_self')
      }
    }
  }, [])
  const link = {
    pathname: '/[slug]/p',
    query: {
      slug: props.data?.slug,
    },
  }
  const productDetail = DetailStore.useContainer()
  const cartState = CartState.useContainer()
  const parentRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [mounted, setMounted] = useState(false)

  useLayoutEffect(() => {
    if (window) {
      setMounted(true)
    }
  }, [])

  let special_price = props.data.special_price
  let price = props.data.price
  const saveAmt = price - special_price
  let sellingprice = sellingPrice(props?.data?.prices[0]?.sp)
  let mrpprice = mrpPrice(props?.data?.mrp[0]?.price)
  return (
    <div
      className={` ${style.card} ${
        !props.props.noBorder ? style['card--border'] : ''
      } no-select`}
      onClick={() => {}}
    >
      <div>
        <Link
          href={link}
          passHref
          className="m-auto"
          style={{
            width: '100%',
            position: 'relative',
          }}
          target={target}
        >
          <Img
            style={{
              // height: !!props.props.imgHeight ? props.props.imgHeight : '100%',
              width: !!props.props.imgWidth ? props.props.imgWidth : '100%',
              margin: '0 auto',
              paddingTop: '20px',
            }}
            rWidth={{
              mobileweb: 300,
              web: 450,
            }}
            rHeight={{
              mobileweb: 300,
              web: 450,
            }}
            deviceType={props.props.mode}
            placeholder="blur"
            className={`${style.image}`}
            src={`${props.data.thumb ? props.data.thumb : props.data.image}`}
            alt={props?.data?.image?.alt}
            // layout="fill"
            // objectFit="contain"
            loader={() =>
              `${props.data.thumb ? props.data.thumb : props.data.image}`
            }
            blurDataURL={`${
              props.data.thumb ? props.data.thumb : props.data.image
            }`}
          />
        </Link>

        <div className={`${style.card__description} whitespace-normal `}>
          <Link href={link} target={target}>
            <b className=" break-normal ...">{props.data.name}</b>
            <small
              className={`${style.product_variant} ${
                props.data.option_value ? 'visible' : 'invisible'
              }`}
            >
              {props.data.option_value}
            </small>
            <div className={style.storage} />
            <div className={style.price1}>
              <span className={` mr-2 ${style.pricepart}`}>
                {Number(mrpprice[0]?.price) > Number(sellingprice[0]?.price)
                  ? PriceFormat(Number(sellingprice[0]?.price))
                  : PriceFormat(Number(mrpprice[0]?.price))}
              </span>
              <s className={` text-gray-300 ${style.specialprice}`}>
                {Math.round(
                  100 -
                    (Number(sellingprice[0]?.price) /
                      Number(mrpprice[0]?.price)) *
                      100
                ) > 0 &&
                  (Number(mrpprice[0]?.price) >= Number(sellingprice[0]?.price)
                    ? PriceFormat(mrpprice[0]?.price)
                    : PriceFormat(sellingprice[0]?.price))}
              </s>
              <span style={{ flex: 1 }} />
              {props.data.rating && props.data.review_count > 0 ? (
                <div>
                  <Star color="#f46b27" type="FILL" />
                  <b>{props.data.rating}</b>
                </div>
              ) : (
                ''
              )}
              <div
                className={`${style.savings} ${
                  Number(sellingprice[0]?.price) < Number(mrpprice[0]?.price)
                    ? 'visible '
                    : 'invisible h-4'
                }`}
              ></div>
            </div>
            <p className="text-xs text-blue-600 mt-2">
              {mrpprice[0]?.price < sellingprice[0]?.price
                ? ''
                : Number(mrpprice[0]?.price) - sellingprice[0]?.price
                ? `Save Rs.  ${PriceFormat(
                    Number(mrpprice[0]?.price) - sellingprice[0]?.price
                  )}`
                : ''}
            </p>
          </Link>
        </div>
      </div>
      <div className="flex items-center cursor-pointer">
        <span className={style['product-tool-tip2']}>
          <FavouriteButton
            // id={props.data.slug}
            item_code={props.data.item_code}
            slug={props.data.code}
            pimStyle={props.styles}
          />
        </span>
      </div>
    </div>
  )
}

export default ProductTwo
