import Link from 'next/link'
import React from 'react'
import styleee from './text.module.scss'

const Text = ({
  text,
  style,
  link,
  page,
  product,
  linktype,
  mode,
  deviceMode,
}: any) => {
  let styl = mode && mode[deviceMode]?.style
  const bannerLink = (element: any) => {
    switch (linktype) {
      case 'EXTERNAL':
        return `${element.url}`
      case 'PRODUCT':
        if (element.pd_type == 'VARIANT') {
          return {
            pathname: '/[slug]/p',
            query: {
              slug: element.code,
            },
          }
        } else {
          return {
            pathname: `/s`,
            query: {
              categories_slug: `categories_slug:=[\`${element.code}\`]`,
            },
          }
        }
      case 'PAGE':
        if (page.pg_type == 'GENERAL') {
          return {
            pathname: '/[slug]/page',
            query: {
              type: 'general',
              slug: page.code,
            },
          }
        } else if (page.pg_type == 'CATEGORY') {
          return {
            pathname: '/[slug]/page',
            query: {
              type: 'category',
              slug: page.code,
            },
          }
        } else {
          return {
            pathname: '/[slug]/page',
            query: {
              type: 'brand',
              slug: page.code,
            },
          }
        }

      default:
        return `${element.url}`
    }
  }
  const Lnk = (props: any) => {
    const { children } = props
    return linktype == 'NONE' ? (
      <>{children}</>
    ) : (
      <Link
        href={bannerLink(props)}
        passHref
        target={props?.target}
        className={props.className}
      >
        {children}
      </Link>
    )
  }
  return (
    <p
      style={{
        textAlign: style?.textAlign,
        color: style?.fontColor,
        fontSize: style?.fontSize,
        fontWeight: style?.fontStyle?.isBold && 'bold',
        fontStyle: style?.fontStyle?.isItalic && 'italic',
        textDecoration: style?.fontStyle?.isUnderline
          ? 'underline'
          : style?.fontStyle?.isStrikeThrough
          ? 'line-through'
          : '',
      }}
      className={`p-1 pt-0 `}
    >
      <Lnk {...link} {...page} {...product}>
        {styl == 'style4' ? (
          <span className={styleee.transform}>{text}</span>
        ) : (
          <span>{text}</span>
        )}
      </Lnk>
    </p>
  )
}
export default Text
