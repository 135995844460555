import Link from 'next/link'
import React, { useEffect, useState } from 'react'
import { StoreService } from '~/services/stores.service'
import { StarsReview } from '../../stars/stars-review'
import { PhoneIcon } from '../../icons/phone'
import { Clock } from '../../icons/clock'
import { Maplocation } from '../../icons/map'
import { StoreIcon } from '../../icons/store-icon'
import Style from '../../../../styles/home.module.scss'
import { Logger } from '~/util'
import { LocationMap } from '../../icons/location'

const Review = ({ review }: any) => {
  const [showMoreStates, setShowMoreStates] = useState<boolean[]>([])
  const [showroomID, setShowroomID] = useState([] as any)
  useEffect(() => {
    const data = {
      ['placeID']: review,
    }
    StoreService.StorePlaceID(data)
      .then((res) => {
        setShowroomID(res.data.data)
      })
      .catch((err) => {
        Logger.error(err)
      })
  }, [review])

  useEffect(() => {
    if (showroomID?.result?.reviews) {
      setShowMoreStates(new Array(showroomID.result.reviews.length).fill(false))
    }
  }, [showroomID?.result?.reviews])
  const toggleShowMore = (index: number) => {
    setShowMoreStates((prev) => {
      const newState = [...prev]
      newState[index] = !newState[index]
      return newState
    })
  }

  const color = [
    '#6ee673',
    '#f36264',
    '#f8a077',
    '#fdc163',
    '#6da3fb',
    '#6ee673',
  ]
  const colors = [
    '#6ee673',
    '#f36264',
    '#f8a077',
    '#fdc163',
    '#6da3fb',
    '#6ee673',
  ]
  const reviewdata = Math.floor(Number(showroomID?.result?.reviews?.length) / 3)
  const reviewremain = Math.floor(
    Number(showroomID?.result?.reviews?.length) % 3
  )

  return (
    <>
      {showroomID != '' && (
        <>
          <div className={`grid grid-cols-12 gap-4 ${Style.review_container}`}>
            <div
              className={`col-span-12 md:col-span-12 lg:col-span-9 flex items-center `}
            >
              <div className="...">
                <div className="mb-4 flex flex-row ...">
                  <div className="text-center sm:text-left md:text-left lg:text-left text-sm pl-2 text-black font-normal leading-7">
                    <p className={`${Style.head_title}`}>
                      {showroomID?.result?.name}
                    </p>
                  </div>
                </div>
                <div className="mb-6 flex flex-row ...">
                  <div className="text-center sm:text-left md:text-left lg:text-right text-base font-medium  pl-2  capitalize">
                    {showroomID?.result?.vicinity}
                  </div>
                </div>
                <div className="grid grid-cols-12 h-full  w-full  ml-2">
                  <div className="col-span-12 md:col-span-8 flex items-center">
                    <div className="flex flex-col sm:flex-row items-center max-lg:justify-center w-full h-full">
                      <div className="sm:pl-3 border-gray-200 flex items-center justify-center flex-col">
                        <h2 className="font-manrope font-bold text-5xl text-black text-center mb-4">
                          {showroomID?.result?.rating}
                        </h2>
                        <div
                          className={`flex items-center gap-3 mb-4 ${Style.star_height}`}
                        >
                          <StarsReview
                            rating={showroomID?.result?.rating}
                            color={colors[showroomID?.result?.rating]}
                          />
                        </div>
                        <p className="font-normal text-lg leading-8 text-black">
                          Over all{' '}
                          {showroomID?.result?.user_ratings_total.toLocaleString()}{' '}
                          reviews
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-4 max-lg:mt-8 md:pl-8">
                    <div className="flex items-center flex-col justify-center w-full h-full ">
                      <button className="rounded-full px-6 py-4 bg-indigo-600 font-semibold text-lg text-white whitespace-nowrap  w-8/12 md:w-full lg:w-full text-center shadow-sm shadow-transparent transition-all duration-500 hover:bg-indigo-700 hover:shadow-indigo-400">
                        <Link
                          href={
                            'https://search.google.com/local/writereview?placeid=' +
                            review
                          }
                          target="_blank"
                        >
                          Write Review
                        </Link>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-12 md:col-span-12 lg:col-span-3 flex  justify-center items-center text-white text-2xl font-extrabold md:justify-between">
              <div className={``}>
                <div className="grid grid-cols-1 gap-2 pt-10">
                  <div className="...">
                    <div>
                      <div className="mb-10 flex flex-row ...">
                        <div className={Style.icon}>
                          <PhoneIcon height="20px" width="20px" />
                        </div>
                        <div>
                          <div className="text-base pl-10 text-black font-normal leading-7">
                            <p>{showroomID?.result?.formatted_phone_number}</p>
                          </div>
                        </div>
                      </div>
                      <div className="mb-10 flex flex-row ...">
                        <div className={Style.icon3}>
                          <LocationMap />
                        </div>
                        <div>
                          <div
                            className={`cursor-pointer font-normal text-base pl-10 leading-7 ${Style.location}`}
                          >
                            {showroomID.result != undefined ? (
                              <Link
                                href={
                                  showroomID != 'undefined' &&
                                  showroomID?.result?.url
                                }
                                target="_blank"
                              >
                                Drive Location
                              </Link>
                            ) : (
                              <Link href="" target="_blank">
                                Drive Location
                              </Link>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="mb-10 flex flex-row ...">
                        <div className={Style.icon1}>
                          <StoreIcon />
                        </div>
                        <div>
                          <Link href="/showrooms" target="_blank">
                            <div
                              className={`text-base pl-10 font-normal leading-7 ${Style.locate}`}
                            >
                              <p>Locate Store</p>
                            </div>
                          </Link>
                        </div>
                      </div>
                      <div className="mb-10 flex flex-row ...">
                        <div className={Style.icons}>
                          <Clock />
                        </div>
                        <div>
                          <div
                            className={`pl-10 text-base font-normal leading-7 ${Style.time_color}`}
                          >
                            <p>10 AM to 10 PM</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-12">
            <div className={`px-4 mx-auto ${Style.review_listtitle}`}>
              <div className="flex flex-col">
                <div className="text-center">
                  <h2 className="mt-4 text-3xl  text-gray-900 sm:text-3xl xl:text-4xl font-pj font-bold">
                    Customer reviews
                  </h2>
                </div>
                <div className="relative mt-14 md:mt-14 md:order-2">
                  <div className="absolute -inset-x-1 inset-y-16 md:-inset-x-2 md:-inset-y-6">
                    <div className="w-full h-full max-w-5xl mx-auto rounded-3xl opacity-30 blur-lg filter"></div>
                  </div>
                  <div className="relative grid max-w-lg grid-cols-1 gap-6 mx-auto md:max-w-none lg:gap-10 md:grid-cols-3">
                    {showroomID?.result?.reviews
                      ?.slice(0, reviewdata * 3)
                      .map((i, j) => (
                        <div
                          className="flex flex-col overflow-hidden shadow-xl rounded-2xl"
                          key={j}
                        >
                          <div className="flex flex-col justify-between flex-1 p-6 bg-white pb-4">
                            <div className="flex-1">
                              <blockquote className="flex-1">
                                <p className="text-lg leading-relaxed text-gray-900 font-pj text-justify">
                                  {showMoreStates[j] ? (
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: i.text,
                                      }}
                                    />
                                  ) : (
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: i.text.slice(0, 150),
                                      }}
                                    />
                                  )}
                                  {i.text.length > 150 && (
                                    <span
                                      onClick={() => toggleShowMore(j)}
                                      className="text-yellow-500 cursor-pointer"
                                    >
                                      {showMoreStates[j]
                                        ? ' read less'
                                        : '...read more'}
                                    </span>
                                  )}
                                </p>
                              </blockquote>
                            </div>
                            <div className="flex items-center pt-8">
                              <img
                                src={i?.profile_photo_url}
                                alt=""
                                className="h-10 w-10 flex-none rounded-full"
                              />
                              <div className="ml-4 flex-auto">
                                <div className="font-medium">
                                  {i.author_name}
                                </div>
                                <div className="mt-1 text-slate-700">
                                  {i.relative_time_description}
                                </div>
                              </div>
                              <div className=" hidden sm:hidden md:hidden lg:hidden xl:block pointer-events-auto ml-4 flex-none rounded-md px-2 py-[0.3125rem] font-medium text-slate-700 shadow-sm ring-1 ring-slate-700/10 hover:bg-slate-50">
                                <StarsReview
                                  rating={i.rating}
                                  color={color[i?.rating]}
                                />
                              </div>
                            </div>
                            <div className="flex justify-end md:mt-4">
                              <div className="block sm:block md:block lg:block xl:hidden pointer-events-auto ml-4 flex-none rounded-md px-2 py-[0.3125rem] font-medium text-slate-700 shadow-sm ring-1 ring-slate-700/10 hover:bg-slate-50">
                                <StarsReview
                                  rating={i.rating}
                                  color={color[i?.rating]}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="relative mt-14 md:mt-14 md:order-2">
                  <div className="absolute -inset-x-1 inset-y-16 md:-inset-x-2 md:-inset-y-6">
                    <div className="w-full h-full max-w-5xl mx-auto rounded-3xl opacity-30 blur-lg filter"></div>
                  </div>
                  <div
                    className={`relative grid max-w-lg grid-cols-1 gap-6 mx-auto md:max-w-none lg:gap-10 md:grid-cols-${reviewremain} lg:grid-cols-${reviewremain}`}
                  >
                    {showroomID?.result?.reviews
                      .slice(reviewdata * 3, reviewdata * 3 + reviewremain)
                      .map((i, j) => (
                        <div
                          className="flex flex-col overflow-hidden shadow-xl rounded-2xl"
                          key={reviewdata * 3 + j}
                        >
                          <div className="flex flex-col justify-between flex-1 p-6 bg-white pb-4">
                            <div className="flex-1">
                              <blockquote className="flex-1">
                                <p className="text-lg leading-relaxed text-gray-900 font-pj text-justify">
                                  {showMoreStates[reviewdata * 3 + j] ? (
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: i.text,
                                      }}
                                    />
                                  ) : (
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: i.text.slice(0, 255),
                                      }}
                                    />
                                  )}
                                  {i.text.length > 150 && (
                                    <span
                                      onClick={() =>
                                        toggleShowMore(reviewdata * 3 + j)
                                      }
                                      className="text-yellow-500 cursor-pointer"
                                    >
                                      {showMoreStates[reviewdata * 3 + j]
                                        ? ' read less'
                                        : '...read more'}
                                    </span>
                                  )}
                                </p>
                              </blockquote>
                            </div>
                            <div className="flex items-center pt-8">
                              <img
                                src={i?.profile_photo_url}
                                alt=""
                                className="h-10 w-10 flex-none rounded-full"
                              />
                              <div className="ml-4 flex-auto">
                                <div className="font-medium">
                                  {i.author_name}
                                </div>
                                <div className="mt-1 text-slate-700">
                                  {i.relative_time_description}
                                </div>
                              </div>
                              <div className="hidden sm:hidden md:hidden lg:block pointer-events-auto ml-4 flex-none rounded-md px-2 py-[0.3125rem] font-medium text-slate-700 shadow-sm ring-1 ring-slate-700/10 hover:bg-slate-50">
                                <StarsReview
                                  rating={i.rating}
                                  color={color[i?.rating]}
                                />
                              </div>
                            </div>
                            <div className="flex justify-end md:mt-4">
                              <div className="block sm:block md:block lg:hidden pointer-events-auto ml-4 flex-none rounded-md px-2 py-[0.3125rem] font-medium text-slate-700 shadow-sm ring-1 ring-slate-700/10 hover:bg-slate-50">
                                <StarsReview
                                  rating={i.rating}
                                  color={color[i?.rating]}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default Review
