import Link from 'next/link'
import React, { FC, useState, useEffect, useRef } from 'react'
import { ChevronBottom } from '~/components/ui/icons/chevron-bottom'
import PageNavChild from './navigation-child/navigation-child'
import style from './page-navigation.module.scss'

const PageNavigation: FC<any> = (props) => {
  const [show, setShow] = useState(-1)
  const [data, setData] = useState(props)
  const [isHover, setIsHover] = useState(-1)

  useEffect(() => {
    if (!!props.navData && !!props?.navData[0]?.children) {
      setData(props)
    }
  }, [props])
  return (
    <div className={style.page_navigation}>
      <div className={style.menubar}>
        {!!data.navData &&
          data.navData[0]?.children.map((item: any, index: number) => (
            <div className={style.menu_section} key={index}>
              {!!item.children ? (
                <div>
                  <div
                    style={{
                      backgroundColor:
                        isHover === index
                          ? `${props.styles.primary.colorHover.background}`
                          : `${props.styles.primary.color.background}`,
                      color:
                        isHover === index
                          ? `${props.styles.primary.colorHover.text}`
                          : `${props.styles.primary.color.text}`,
                      borderColor: props.styles.primary.isSeparator
                        ? props.styles.primary.color.separator
                        : 'transparent',
                      borderRightWidth: props.styles.primary.isSeparator
                        ? 1
                        : 0,
                    }}
                    onMouseEnter={() =>
                      setIsHover(isHover === index ? -1 : index)
                    }
                    onMouseLeave={() =>
                      setIsHover(isHover === index ? -1 : index)
                    }
                    className="flex items-center justify-center px-2 py-2"
                    onClick={() => setShow(show === index ? -1 : index)}
                  >
                    <span>{item.title}</span>
                    <ChevronBottom
                      color={props.styles.primary.color.arrow}
                      classname={''}
                    ></ChevronBottom>
                  </div>
                  {show === index && (
                    <div className={` ${style.dropdowncontent}`}>
                      <PageNavChild
                        data={item}
                        dynamicStyle={props.styles.secondary}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div
                  className={`px-2 py-2 ${style.menu_section}`}
                  style={{
                    backgroundColor:
                      isHover === index
                        ? `${props.styles.primary.colorHover.background}`
                        : `${props.styles.primary.color.background}`,
                    color:
                      isHover === index
                        ? `${props.styles.primary.colorHover.text}`
                        : `${props.styles.primary.color.text}`,
                    borderColor: props.styles.primary.isSeparator
                      ? props.styles.primary.color.separator
                      : 'transparent',
                    borderRightWidth: props.styles.primary.isSeparator ? 1 : 0,
                  }}
                  onMouseEnter={() =>
                    setIsHover(isHover === index ? -1 : index)
                  }
                  onMouseLeave={() =>
                    setIsHover(isHover === index ? -1 : index)
                  }
                >
                  <Link href={item.url} target={item.target}>
                    {item.title}
                  </Link>
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  )
}

export default PageNavigation
