import React from 'react'
import { VideoList } from './video-card/video-card'
const Video = ({ video: VideoProps, mode }: any) => {
  return (
    <div>
      <VideoList data={VideoProps} mode={mode} />
    </div>
  )
}
export default Video
