import Link from 'next/link'
import React, { FC, useEffect, useState } from 'react'
import { IsMobile, PriceFormat } from '~/util'
import Img from '../../img/img'
import style from './productfifteen.module.scss'
import { Star } from '../../icons/star'
import { mrpPrice, sellingPrice } from '~/util/price'

type ProductCardData = {
  props: any
  data: any
  styles: string
  nobrorder: any
  mode?: string
  imgHeight?: any
  imgWidth?: string
}

const ProductFifteen: FC<ProductCardData> = (props) => {
  const [target, setTarget] = useState('_blank')
  useEffect(() => {
    if (window) {
      if (window.matchMedia('(display-mode: standalone)').matches) {
        setTarget('_self')
      }
      if (IsMobile()) {
        setTarget('_self')
      }
    }
  }, [])
  const link = {
    pathname: '/[slug]/p',
    query: {
      slug: props.data?.slug,
    },
  }
  let sellingprice = sellingPrice(props?.data?.prices[0]?.sp)
  let mrpprice = mrpPrice(props?.data?.mrp[0]?.price)
  return (
    <div className={` ${style.card} `}>
      <Link href={link} passHref target={target}>
        {!!props.data.gallery ? (
          props.data.gallery.map(
            (item, index) =>
              item.type.toLowerCase() == 'image' && (
                <div className={style[`galleryChild_` + index]} key={index}>
                  <Img
                    rWidth={{
                      mobileweb: 140,
                      web: 140,
                    }}
                    rHeight={{
                      mobileweb: 140,
                      web: 140,
                    }}
                    deviceType={props.props.mode}
                    className={`${style.image}`}
                    src={`${item?.media?.url}`}
                    alt={props?.data?.image?.alt}
                    blurEffect={true}
                    loader={() =>
                      `${
                        props.data.thumb ? props.data.thumb : props.data.image
                      }`
                    }
                    blurDataURL={`${
                      props.data.thumb ? props.data.thumb : props.data.image
                    }`}
                  />
                </div>
              )
          )
        ) : (
          <Img
            rWidth={{
              mobileweb: 140,
              web: 140,
            }}
            rHeight={{
              mobileweb: 140,
              web: 140,
            }}
            deviceType={props.props.mode}
            placeholder="blur"
            className={`${style.image}`}
            src={`${props.data.thumb ? props.data.thumb : props.data.image}`}
            alt={props?.data?.image?.alt}
            // layout="fill"
            // objectFit="contain"
            loader={() =>
              `${props.data.thumb ? props.data.thumb : props.data.image}`
            }
            blurDataURL={`${
              props.data.thumb ? props.data.thumb : props.data.image
            }`}
          />
        )}
        <div className={style.card_bottom}>
          <div className={`${style.best_seller}`}>
            <p>{/* Best Seller */}</p>
            {props.data.rating && (
              <div className="flex items-center ">
                <Star color="#000" type="FILL" size="0.6" />
                <span>{props.data.rating}</span>
              </div>
            )}
          </div>
          <div className={`${style.card__description} `}>
            <h3>{props.data.name}</h3>
            <h4>{props.data.option_value}</h4>
            <div className="flex items-center justify-between">
              <p>
                {sellingprice?.length != 0
                  ? PriceFormat(sellingprice[0]?.price)
                  : PriceFormat(mrpprice[0]?.price)}
              </p>
              {
                // Number(props.data.special_price) !== 0 &&
                Math.round(
                  100 -
                    (Number(sellingprice[0]?.price) /
                      Number(mrpprice[0]?.price)) *
                      100
                ) > 0 && (
                  <span className={style.offer}>
                    {Math.round(
                      100 -
                        (Number(sellingprice[0]?.price) /
                          Number(mrpprice[0]?.price)) *
                          100
                    )}
                    % OFF
                  </span>
                )
              }
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default ProductFifteen
