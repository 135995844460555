import React, {
  FC,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import style from '~/styles/home.module.scss'

const Column: any = (props: any) => {
  const mode = useMemo(() => {
    if (!!!props.deviceMode) {
      return {
        colspan: 0,
        isScroll: false,
        home_sidebanner: '',
        coulmnadjust: '',
      }
    }
    return props.mode && props.mode[props.deviceMode]
  }, [props, props.deviceMode])

  const childRef = useRef(null)
  let coulmnstyl = props.mode?.style
  const layoutImgRef2 = useRef(null)
  const layoutImgRef3 = useRef(null)
  const layoutImgRef4 = useRef(null)
  const layoutImgRef5 = useRef(null)
  const layoutImgRef5Child = useRef(null)
  const layoutImgRef6 = useRef(null)
  const layoutImgRef7 = useRef(null)
  const layoutImgRef7Child = useRef(null)
  const layoutImgRef8 = useRef(null)
  const layoutImgRef6Child = useRef(null)
  const [layout7Height, setLayout7Height] = useState(0)
  const layoutImageHeight = () => {
    if (!!props?.children && ['LAYOUT2'].includes(props.layout.toUpperCase())) {
      let layoutTwoVideos =
        layoutImgRef2.current &&
        layoutImgRef2.current?.querySelectorAll('.ref_id')
      let layoutTwoImages =
        layoutImgRef2.current &&
        layoutImgRef2.current?.querySelectorAll('img')[0]
      if (!!layoutTwoImages) {
        for (let i = 0; i < layoutTwoVideos.length; i++) {
          layoutTwoVideos[i].style.minHeight =
            layoutTwoImages?.clientHeight + 'px'
          layoutTwoVideos[i].style.height = layoutTwoImages?.clientHeight + 'px'
        }
      }
    }

    if (!!props?.children && ['LAYOUT3'].includes(props.layout.toUpperCase())) {
      let layoutThreeVideos =
        layoutImgRef3.current &&
        layoutImgRef3.current?.querySelectorAll('.ref_id')
      let layoutThreeImages =
        layoutImgRef3.current &&
        layoutImgRef3.current?.querySelectorAll('img')[0]
      if (!!layoutThreeImages) {
        for (let i = 0; i < layoutThreeVideos.length; i++) {
          layoutThreeVideos[i].style.minHeight =
            layoutThreeImages?.clientHeight + 'px'
          layoutThreeVideos[i].style.height =
            layoutThreeImages?.clientHeight + 'px'
        }
      }
    }

    if (!!props?.children && ['LAYOUT5'].includes(props.layout.toUpperCase())) {
      let layoutFiveTopVideos =
        layoutImgRef5.current &&
        layoutImgRef5.current?.querySelectorAll('.ref_id')
      let layoutFiveVideos =
        layoutImgRef5Child.current &&
        layoutImgRef5Child.current?.querySelectorAll('.ref_id')
      let layoutFiveImages =
        layoutImgRef5Child.current &&
        layoutImgRef5Child.current?.querySelectorAll('img')[0]
      if (!!layoutFiveVideos) {
        if (!!layoutFiveImages) {
          for (let i = 0; i < layoutFiveVideos.length; i++) {
            layoutFiveVideos[i].style.minHeight =
              layoutFiveImages?.clientHeight + 'px'
            layoutFiveVideos[i].style.height =
              layoutFiveImages?.clientHeight + 'px'
          }
        }
      }
    }

    if (!!props?.children && ['LAYOUT6'].includes(props.layout.toUpperCase())) {
      let layoutSixVideos =
        layoutImgRef6Child.current &&
        layoutImgRef6Child.current?.querySelectorAll('.ref_id')
      let layoutSixImages =
        layoutImgRef6Child.current &&
        layoutImgRef6Child.current?.querySelectorAll('img')[0]
      if (!!layoutSixVideos) {
        if (!!layoutSixImages) {
          for (let i = 0; i < layoutSixVideos.length; i++) {
            layoutSixVideos[i].style.minHeight =
              layoutSixImages?.clientHeight + 'px'
            layoutSixVideos[i].style.height =
              layoutSixImages?.clientHeight + 'px'
          }
        }
      }
    }

    if (!!props?.children && ['LAYOUT7'].includes(props.layout.toUpperCase())) {
      let layoutSevenVideos =
        layoutImgRef7.current &&
        layoutImgRef7.current?.querySelectorAll('.ref_id')
      let layoutSevenChildVideos =
        layoutImgRef7Child.current &&
        layoutImgRef7Child.current?.querySelectorAll('.ref_id')
      let layoutSevenChildImages =
        layoutImgRef7Child.current &&
        layoutImgRef7Child.current?.querySelectorAll('img')
      let layoutSevenImages =
        layoutImgRef7.current && layoutImgRef7.current?.querySelectorAll('img')
      if (!!layoutSevenChildVideos) {
        if (!!layoutSevenChildImages) {
          if (!!layoutSevenImages) {
            for (let i = 0; i < layoutSevenChildVideos.length; i++) {
              layoutSevenChildVideos[i].style.minHeight =
                layoutSevenImages[0]?.clientHeight / 2 - 5 + 'px'
              layoutSevenChildVideos[i].style.height =
                layoutSevenImages[0]?.clientHeight / 2 - 5 + 'px'
            }
            for (let j = 0; j < layoutSevenChildImages.length; j++) {
              layoutSevenChildImages[j].style.minHeight =
                layoutSevenImages[0]?.clientHeight / 2 - 5 + 'px'
              layoutSevenChildImages[j].style.height =
                layoutSevenImages[0]?.clientHeight / 2 - 5 + 'px'
            }
          }
          if (!!layoutSevenVideos && !!layoutSevenVideos[0]) {
            layoutSevenVideos[0].style.minHeight = 400 + 'px'
            layoutSevenVideos[0].style.height = 400 + 'px'
            for (let i = 0; i < layoutSevenChildVideos.length; i++) {
              layoutSevenChildVideos[i].style.minHeight = 195 + 'px'
              layoutSevenChildVideos[i].style.height = 195 + 'px'
            }
            for (let j = 0; j < layoutSevenChildImages.length; j++) {
              layoutSevenChildImages[j].style.minHeight = 195 + 'px'
              layoutSevenChildImages[j].style.height = 195 + 'px'
            }
          }
        }
      }
    }

    if (!!props?.children && ['LAYOUT8'].includes(props.layout.toUpperCase())) {
      let layoutEightVideos =
        layoutImgRef8.current &&
        layoutImgRef8.current?.querySelectorAll('.ref_id')
      let layoutEightImages =
        layoutImgRef8.current &&
        layoutImgRef8.current?.querySelectorAll('img')[0]
      if (!!layoutEightVideos) {
        if (!!layoutEightImages) {
          for (let i = 0; i < layoutEightVideos.length; i++) {
            layoutEightVideos[i].style.minHeight =
              layoutEightImages?.clientHeight + 'px'
            layoutEightVideos[i].style.height =
              layoutEightImages?.clientHeight + 'px'
          }
        }
      }
    }
  }
  const [scrollElement, setScrollElement] = useState(false)
  const [scrollElementHeight, setScrollElementHeight] = useState(600)
  const handleScroll = () => {
    if (window.scrollY >= 10 && window.scrollY <= 11) {
      setScrollElement(true)
    }
    if (
      window.scrollY >= scrollElementHeight &&
      window.scrollY <= scrollElementHeight + 100
    ) {
      setScrollElement(true)
      setScrollElementHeight(scrollElementHeight + 600)
    }
  }
  useEffect(() => {
    if (scrollElement) {
      layoutImageHeight()
      setScrollElement(false)
    }
  }, [scrollElement])
  useEffect(() => {
    layoutImageHeight()
  }, [])
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [scrollElementHeight])
  const compOrder = props.compOrder
  const compLayoutOrder = compOrder
    ? compOrder.split(',').map((ord) => parseInt(ord) - 1)
    : [0, 1, 2, 3]
  return (
    <>
      {mode?.isVisible && (
        <>
          {' '}
          {!!props?.children && props.layout === 'layout1' && (
            <div
              className={`
              ${
                props?.props?.mode[props?.deviceMode]?.flexView == true
                  ? `${style[`box_data_` + mode?.colspan]}`
                  : props?.props?.mode[props.deviceMode]?.isScroll == true
                  ? `${style[`box_data` + mode?.colspan]}`
                  : `col-span-${mode?.colspan} ${
                      mode?.isVisible == false ? 'hidden' : ''
                    } md:col-span-${
                      mode?.colspan == 10
                        ? 9
                        : mode?.colspan == 2
                        ? 3
                        : mode?.colspan
                    }  lg:col-span-${
                      mode?.colspan == 10
                        ? 9
                        : mode?.colspan == 2
                        ? 3
                        : mode?.colspan
                    }
            } xl:col-span-${mode?.colspan} ${
                      mode?.colspan == 2
                        ? style.home_sidebanner
                        : mode?.colspan == 10
                        ? style.home_sidebanner_slider
                        : ''
                    }`
              }
               ${mode?.style == 'style4' && style.coulmnadjust} ${
                mode?.style == 'style1' && style.magrgin
              }`}
              style={{
                backgroundColor: props.bgColor,
                justifyContent: props.justify,
                alignItems: props.align,
                backgroundImage: `url(${props.bgImage?.url})`,
                height: props.bgImage?.height,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                marginTop: mode?.top,
                marginBottom: mode?.bottom,
                marginRight: mode?.right,
                marginLeft: mode?.left,
                borderRadius: mode?.radius,
              }}
            >
              {props.children}
            </div>
          )}
          {!!props?.children && props.layout === 'layout2' && (
            <div className={`col-span-${mode.colspan}`} ref={layoutImgRef2}>
              <div
                className={`${`mt-1 mb-4 grid grid-cols-12 lg:grid-cols-12 gap-3 `} ${
                  style.layoutDesign2
                }`}
                style={{
                  backgroundColor: props.bgColor,
                  justifyContent: props.justify,
                  alignItems: props.align,
                  backgroundImage: `url(${props.bgImage?.url})`,
                  height: props.bgImage?.height,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  marginTop: mode?.top,
                  marginBottom: mode?.bottom,
                  marginRight: mode?.right,
                  marginLeft: mode?.left,
                  borderRadius: mode?.radius,
                }}
              >
                <div className={'col-span-6'}>
                  {props?.children?.[compLayoutOrder[0]]}
                </div>
                <div className={'col-span-6'}>
                  {props?.children?.[compLayoutOrder[1]]}
                </div>
              </div>
            </div>
          )}
          {!!props?.children && props.layout === 'layout3' && (
            <>
              <div className={`col-span-${mode.colspan}`} ref={layoutImgRef3}>
                <div
                  className={`mt-1 mb-4 grid grid-cols-12 lg:grid-cols-12 gap-3 `}
                  style={{
                    backgroundColor: props.bgColor,
                    justifyContent: props.justify,
                    alignItems: props.align,
                    backgroundImage: `url(${props.bgImage?.url})`,
                    height: props.bgImage?.height,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    marginTop: mode?.top,
                    marginBottom: mode?.bottom,
                    marginRight: mode?.right,
                    marginLeft: mode?.left,
                    borderRadius: mode?.radius,
                  }}
                >
                  <div className={'col-span-4'}>
                    {props?.children?.[compLayoutOrder[0]]}
                  </div>
                  <div className={'col-span-4'}>
                    {props?.children?.[compLayoutOrder[1]]}
                  </div>
                  <div className={'col-span-4'}>
                    {props?.children?.[compLayoutOrder[2]]}
                  </div>
                </div>
              </div>
            </>
          )}
          {!!props?.children && props.layout === 'layout4' && (
            <div
              className={`
               ${
                 props.props.flexView == true
                   ? `${style[`box_data_` + mode?.colspan]}`
                   : props.props.mode[props.deviceMode].isScroll == true
                   ? `${style[`box_data` + mode?.colspan]}`
                   : `col-span-${mode?.colspan} ${
                       mode?.isVisible == false ? 'hidden' : ''
                     } md:col-span-${mode?.colspan == 10}  lg:col-span-${
                       mode?.colspan
                     }
            } xl:col-span-${mode?.colspan} ${mode?.colspan}`
               }`}
              ref={layoutImgRef4}
              style={{
                backgroundColor: props.bgColor,
                justifyContent: props.justify,
                alignItems: props.align,
                backgroundImage: `url(${props.bgImage?.url})`,
                height: props.bgImage?.height,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                marginTop: mode?.top,
                marginBottom: mode?.bottom,
                marginRight: mode?.right,
                marginLeft: mode?.left,
                borderRadius: mode?.radius,
              }}
            >
              <div>{props?.children?.[compLayoutOrder[0]]}</div>
              <div>{props?.children?.[compLayoutOrder[1]]}</div>
            </div>
          )}
          {!!props?.children && props.layout === 'layout5' && (
            <div className={`col-span-${mode.colspan}`}>
              <div
                className={`${`mt-1 mb-4 grid grid-cols-12 sm:grid-cols-12 md:grid-cols-12 lg:grid-cols-12 gap-3 `} ${
                  style.layoutDesign2
                }`}
                style={{
                  backgroundColor: props.bgColor,
                  justifyContent: props.justify,
                  alignItems: props.align,
                  backgroundImage: `url(${props.bgImage?.url})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  marginTop: mode?.top,
                  marginBottom: mode?.bottom,
                  marginRight: mode?.right,
                  marginLeft: mode?.left,
                  borderRadius: mode?.radius,
                }}
              >
                <div className={'col-span-12'} ref={layoutImgRef5}>
                  {props?.children?.[compLayoutOrder[0]]}
                </div>
                <div
                  className={'col-span-12 grid gap-3 grid-cols-12'}
                  ref={layoutImgRef5Child}
                >
                  <div className={'col-span-6'}>
                    {props?.children?.[compLayoutOrder[1]]}
                  </div>
                  <div className={'col-span-6'}>
                    {props?.children?.[compLayoutOrder[2]]}
                  </div>
                </div>
              </div>
            </div>
          )}
          {!!props?.children && props.layout === 'layout6' && (
            <div className={`col-span-${mode.colspan}`}>
              <div
                className={`mt-1 mb-4 grid grid-cols-12 sm:grid-cols-12 md:grid-cols-12 lg:grid-cols-12 gap-3`}
                style={{
                  backgroundColor: props.bgColor,
                  justifyContent: props.justify,
                  alignItems: props.align,
                  backgroundImage: `url(${props.bgImage?.url})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  marginTop: mode?.top,
                  marginBottom: mode?.bottom,
                  marginRight: mode?.right,
                  marginLeft: mode?.left,
                  borderRadius: mode?.radius,
                }}
              >
                <div
                  className={'col-span-12 grid gap-3 grid-cols-12'}
                  ref={layoutImgRef6Child}
                >
                  <div className={'col-span-6'}>
                    {props?.children?.[compLayoutOrder[0]]}
                  </div>
                  <div className={'col-span-6'}>
                    {props?.children?.[compLayoutOrder[1]]}
                  </div>
                </div>
                <div className={'col-span-12'} ref={layoutImgRef6}>
                  {props?.children?.[compLayoutOrder[2]]}
                </div>
              </div>
            </div>
          )}
          {!!props?.children && props.layout === 'layout7' && (
            <div className={`col-span-${mode.colspan}`}>
              <div
                className={`mt-1 mb-4 grid  grid-cols-12  lg:grid-cols-12 gap-3`}
                style={{
                  backgroundColor: props.bgColor,
                  justifyContent: props.justify,
                  alignItems: props.align,
                  backgroundImage: `url(${props.bgImage?.url})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  marginTop: mode?.top,
                  marginBottom: mode?.bottom,
                  marginRight: mode?.right,
                  marginLeft: mode?.left,
                  borderRadius: mode?.radius,
                }}
              >
                <div className={'col-span-6'} ref={layoutImgRef7}>
                  {props?.children?.[compLayoutOrder[0]]}
                </div>
                <div className={'col-span-6'} ref={layoutImgRef7Child}>
                  <div style={{ marginBottom: 10 }}>
                    {props?.children?.[compLayoutOrder[1]]}
                  </div>
                  <div>{props?.children?.[compLayoutOrder[2]]}</div>
                </div>
              </div>
            </div>
          )}
          {!!props?.children && props.layout === 'layout8' ? (
            <div className={`col-span-${mode.colspan}`} ref={layoutImgRef8}>
              <div
                className={`mt-1 mb-4 grid  grid-cols-12  lg:grid-cols-12 gap-3`}
                style={{
                  backgroundColor: props.bgColor,
                  justifyContent: props.justify,
                  alignItems: props.align,
                  backgroundImage: `url(${props.bgImage?.url})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  marginTop: mode?.top,
                  marginBottom: mode?.bottom,
                  marginRight: mode?.right,
                  marginLeft: mode?.left,
                  borderRadius: mode?.radius,
                }}
              >
                <div className={`${'col-span-6'} `}>
                  {props?.children?.[compLayoutOrder[0]]}
                </div>
                <div className={`${'col-span-6'} `}>
                  {props?.children?.[compLayoutOrder[1]]}
                </div>
                <div className={`${'col-span-6'} `}>
                  {props?.children?.[compLayoutOrder[2]]}
                </div>
                <div className={`${'col-span-6'} `}>
                  {props?.children?.[compLayoutOrder[3]]}
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  )
}
export default Column
