// @ts-ignore
// @ts-nocheck
import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useMounted } from '~/util/hooks'
import { GridWidthCalculator } from './layout-calc'
import { IsMobile } from '~/util'
import VideoCard from '../../cards/video-card/video-card'
import Model from '../../model/model'
import { CloseNew } from '../../icons/close_new'
import style from '~/styles/home.module.scss'

interface Datum {
  __v: number
  _id: string
  channel: string
  code: string
  createdby: string
  createddate: string
  isActive: boolean
  list: List[]
  modifiedby: string
  modifieddate: string
  name: Name
  org_code?: any
  published: Published
}

interface Published {
  datetime: string
  status: string
  version: number
}

interface Name {
  'en-IN': string
  'ta-IN': string
}

interface Image {
  height: number
  isDynamic: boolean
  shape: string
  url: string
  width: number
}
interface List {
  _id: string
  code: string
  image: Image
  name: string
  shape: string
  type: string
}

const TabVideoGroup = (props: any) => {
  const [_target, setTarget] = useState('_blank')
  const [_show, setShow] = useState(true)
  const parentRef = useRef(null)
  useMounted(
    parentRef,
    () => {
      if (IsMobile() || window.innerWidth <= 550) {
        setShow(true)
      } else {
        setShow(false)
      }
    },
    []
  )
  useEffect(() => {
    if (window) {
      if (window.matchMedia('(display-mode: standalone)').matches) {
        setTarget('_self')
      }
      if (IsMobile()) {
        setTarget('_self')
      }
    }
  }, [])

  const [loader, _setLoader] = useState(false)
  const [_width, setWidth] = useState(1200)
  const wrapper = useRef<HTMLDivElement>(null)
  const [selectedVideo, setSelectedVideo] = useState(null as any)

  const variantClassMap: any = {
    Youtube: 'https://www.youtube.com/embed/',
    Vimeo: 'https://player.vimeo.com/video/',
    Dailymotion: 'https://www.dailymotion.com/embed/video/',
  }

  useEffect(() => {
    const changeWidth = () => {
      let w = GridWidthCalculator(wrapper)
      setWidth(w)
    }
    changeWidth()
    window.onreset = () => changeWidth()
  }, [])

  const hasVideo = useCallback(() => {
    return !!selectedVideo
  }, [selectedVideo])

  const gridLayout = () => {
    if (
      props?.props?.tabpaneltype?.tabcontent?.videoListComp?.items?.length >= 1
    ) {
      switch (
        props?.props?.tabpaneltype?.tabcontent?.videoListComp?.mode[props.mode]
          ?.layout
      ) {
        case 'LAYOUT1':
        case 'NONE':
          return (
            <>
              <div
                className={`mt-1 mb-4 grid grid-cols-12 sm:grid-cols-12 md:grid-cols-12 lg:grid-cols-12 gap-3`}
              >
                <div className={'col-span-12 grid gap-3 grid-cols-12'}>
                  <div
                    className={`${
                      props.mode == 'mobileweb' ? 'col-span-12' : 'col-span-6'
                    }`}
                  >
                    <div className={'grid gap-x-2 grid-cols-4'}>
                      {props?.props?.tabpaneltype?.tabcontent?.videoListComp
                        .items?.length >= 1 &&
                        props?.props?.tabpaneltype?.tabcontent?.videoListComp?.items
                          .slice(0, 4)
                          .map((i: any, j: number) => (
                            <div key={j} className={`col-span-2 ... w-fit`}>
                              <VideoCard
                                attr={i.attr}
                                mode={props.mode}
                                title=""
                                play={() =>
                                  setSelectedVideo(
                                    `${variantClassMap[i.attr?.playerType]}${
                                      i.attr?.videoID
                                    }?autoplay=${i.attr?.loop ? 1 : 0}&loop=${
                                      i.attr?.loop ? 1 : 0
                                    }&controls=${
                                      i.attr?.controls ? 1 : 0
                                    }&playlist=${i.attr?.videoID}`
                                  )
                                }
                              />
                            </div>
                          ))}
                    </div>
                  </div>
                  <div
                    className={`${
                      props.mode == 'mobileweb' ? 'col-span-12' : 'col-span-6'
                    }`}
                  >
                    <VideoCard
                      mode={props.mode}
                      attr={
                        props?.props?.tabpaneltype?.tabcontent?.videoListComp
                          .items[4]?.attr
                      }
                      height={true}
                      play={() =>
                        setSelectedVideo(
                          `${
                            variantClassMap[
                              props?.props?.tabpaneltype?.tabcontent
                                ?.videoListComp.items[4]?.attr?.playerType
                            ]
                          }${
                            props?.props?.tabpaneltype?.tabcontent
                              ?.videoListComp.items[4]?.attr?.videoID
                          }?autoplay=${
                            props?.props?.tabpaneltype?.tabcontent
                              ?.videoListComp.items[4]?.attr?.loop
                              ? 1
                              : 0
                          }&loop=${
                            props?.props?.tabpaneltype?.tabcontent
                              ?.videoListComp.items[4]?.attr?.loop
                              ? 1
                              : 0
                          }&controls=${
                            props?.props?.tabpaneltype?.tabcontent
                              ?.videoListComp.items[4]?.attr?.controls
                              ? 1
                              : 0
                          }&playlist=${
                            props?.props?.tabpaneltype?.tabcontent
                              ?.videoListComp.items[4]?.attr?.videoID
                          }`
                        )
                      }
                    />
                    {hasVideo() ? (
                      <div>
                        <Model
                          closeModel={() => setSelectedVideo(null)}
                          model={hasVideo()}
                        >
                          <div
                            className={`${style.shareclose}`}
                            onClick={() => setSelectedVideo(!hasVideo)}
                          >
                            <CloseNew className={`${style.closebutton}`} />
                          </div>
                          <iframe
                            className={style.home_iframe}
                            src={selectedVideo}
                            title="YouTube video player"
                            allow="fullscreen; accelerometer; autoplay; loop; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          />
                        </Model>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </>
          )
        case 'LAYOUT2':
          return (
            <div
              className={`mt-1 mb-4 grid grid-cols-12 sm:grid-cols-12 md:grid-cols-12 lg:grid-cols-12 gap-3`}
            >
              <div className={'col-span-12 grid gap-3 grid-cols-12'}>
                <div
                  className={`${
                    props.mode == 'mobileweb' ? 'col-span-12' : 'col-span-6'
                  }`}
                >
                  <div className="grid grid-cols-1 gap-1">
                    {props?.props?.tabpaneltype?.tabcontent?.videoListComp.items
                      ?.length >= 1 &&
                      props?.props?.tabpaneltype?.tabcontent?.videoListComp?.items
                        .slice(0, 1)
                        .map((i: any, j: number) => (
                          <div key={j} className="...">
                            <VideoCard
                              attr={i.attr}
                              mode={props.mode}
                              title=""
                              height={true}
                              play={() =>
                                setSelectedVideo(
                                  `${variantClassMap[i.attr?.playerType]}${
                                    i.attr?.videoID
                                  }?autoplay=${i.attr?.loop ? 1 : 0}&loop=${
                                    i.attr?.loop ? 1 : 0
                                  }&controls=${
                                    i.attr?.controls ? 1 : 0
                                  }&playlist=${i.attr?.videoID}`
                                )
                              }
                            />
                          </div>
                        ))}
                  </div>
                </div>
                <div
                  className={`${
                    props.mode == 'mobileweb' ? 'col-span-12' : 'col-span-6'
                  }`}
                >
                  <div
                    className={`${
                      props.mode == 'mobileweb'
                        ? 'grid grid-cols-2'
                        : 'grid grid-cols-2 gap-2'
                    }`}
                  >
                    {props?.props?.tabpaneltype?.tabcontent?.videoListComp.items
                      ?.length >= 1 &&
                      props?.props?.tabpaneltype?.tabcontent?.videoListComp?.items
                        .slice(0, 4)
                        .map((i: any, j: number) => (
                          <div key={j} className="...">
                            <VideoCard
                              attr={i.attr}
                              mode={props.mode}
                              title=""
                              play={() =>
                                setSelectedVideo(
                                  `${variantClassMap[i.attr?.playerType]}${
                                    i.attr?.videoID
                                  }?autoplay=${i.attr?.loop ? 1 : 0}&loop=${
                                    i.attr?.loop ? 1 : 0
                                  }&controls=${
                                    i.attr?.controls ? 1 : 0
                                  }&playlist=${i.attr?.videoID}`
                                )
                              }
                            />
                          </div>
                        ))}
                    {hasVideo() ? (
                      <div>
                        <Model
                          closeModel={() => setSelectedVideo(null)}
                          model={hasVideo()}
                        >
                          <div
                            className={`${style.shareclose}`}
                            onClick={() => setSelectedVideo(!hasVideo)}
                          >
                            <CloseNew className={`${style.closebutton}`} />
                          </div>
                          <iframe
                            className={style.home_iframe}
                            src={selectedVideo}
                            title="YouTube video player"
                            allow="fullscreen; accelerometer; autoplay; loop; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          />
                        </Model>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )
        case 'LAYOUT3':
          return (
            <div
              className={`mt-1 mb-4 grid grid-cols-12 sm:grid-cols-12 md:grid-cols-12 lg:grid-cols-12 gap-3`}
            >
              <div className={'col-span-12 grid gap-3 grid-cols-12'}>
                <div
                  className={`${
                    props.mode == 'mobileweb' ? 'col-span-12' : 'col-span-3'
                  }`}
                >
                  <div
                    className={`grid ${
                      props.mode == 'mobileweb' ? 'grid-cols-2 ' : 'grid-cols-1'
                    } gap-1`}
                  >
                    {props?.props?.tabpaneltype?.tabcontent?.videoListComp.items
                      ?.length >= 1 &&
                      props?.props?.tabpaneltype?.tabcontent?.videoListComp?.items
                        .slice(0, 2)
                        .map((i: any, j: number) => (
                          <div key={j} className="...">
                            <VideoCard
                              attr={i.attr}
                              mode={props.mode}
                              title=""
                              play={() =>
                                setSelectedVideo(
                                  `${variantClassMap[i.attr?.playerType]}${
                                    i.attr?.videoID
                                  }?autoplay=${i.attr?.loop ? 1 : 0}&loop=${
                                    i.attr?.loop ? 1 : 0
                                  }&controls=${
                                    i.attr?.controls ? 1 : 0
                                  }&playlist=${i.attr?.videoID}`
                                )
                              }
                            />
                          </div>
                        ))}
                  </div>
                </div>
                <div
                  className={`${
                    props.mode == 'mobileweb' ? 'col-span-12' : 'col-span-6'
                  }`}
                >
                  <div className="grid grid-cols-1 gap-1">
                    {props?.props?.tabpaneltype?.tabcontent?.videoListComp.items
                      ?.length >= 1 &&
                      props?.props?.tabpaneltype?.tabcontent?.videoListComp?.items
                        .slice(2, 3)
                        .map((i: any, j: number) => (
                          <div key={j} className="...">
                            <VideoCard
                              attr={i.attr}
                              mode={props.mode}
                              title=""
                              height={true}
                              play={() =>
                                setSelectedVideo(
                                  `${variantClassMap[i.attr?.playerType]}${
                                    i.attr?.videoID
                                  }?autoplay=${i.attr?.loop ? 1 : 0}&loop=${
                                    i.attr?.loop ? 1 : 0
                                  }&controls=${
                                    i.attr?.controls ? 1 : 0
                                  }&playlist=${i.attr?.videoID}`
                                )
                              }
                            />
                          </div>
                        ))}
                  </div>
                </div>
                <div
                  className={`${
                    props.mode == 'mobileweb' ? 'col-span-12' : 'col-span-3'
                  }`}
                >
                  <div
                    className={`grid ${
                      props.mode == 'mobileweb' ? 'grid-cols-2' : 'grid-cols-1'
                    } gap-1`}
                  >
                    {props?.props?.tabpaneltype?.tabcontent?.videoListComp.items
                      ?.length >= 1 &&
                      props?.props?.tabpaneltype?.tabcontent?.videoListComp?.items
                        .slice(3, 5)
                        .map((i: any, j: number) => (
                          <div key={j} className="...">
                            <VideoCard
                              attr={i.attr}
                              mode={props.mode}
                              title=""
                              play={() =>
                                setSelectedVideo(
                                  `${variantClassMap[i.attr?.playerType]}${
                                    i.attr?.videoID
                                  }?autoplay=${i.attr?.loop ? 1 : 0}&loop=${
                                    i.attr?.loop ? 1 : 0
                                  }&controls=${
                                    i.attr?.controls ? 1 : 0
                                  }&playlist=${i.attr?.videoID}`
                                )
                              }
                            />
                          </div>
                        ))}
                    {hasVideo() ? (
                      <div>
                        <Model
                          closeModel={() => setSelectedVideo(null)}
                          model={hasVideo()}
                        >
                          <div
                            className={`${style.shareclose}`}
                            onClick={() => setSelectedVideo(!hasVideo)}
                          >
                            <CloseNew className={`${style.closebutton}`} />
                          </div>
                          <iframe
                            className={style.home_iframe}
                            src={selectedVideo}
                            title="YouTube video player"
                            allow="fullscreen; accelerometer; autoplay; loop; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          />
                        </Model>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )

        default:
          return loader ? '' : <></>
      }
    }
  }
  return <div ref={wrapper}>{gridLayout()}</div>
}
export default TabVideoGroup
