import Link from 'next/link'
import React, { FC, useEffect, useState } from 'react'
import { IsMobile, PriceFormat } from '~/util'
import Star from '../../icons/star'
import Img from '../../img/img'
import style from './productthirteen.module.scss'
import { mrpPrice, sellingPrice } from '~/util/price'

type ProductCardData = {
  props: any
  data: any
  pimStyle?: string
  styles: string
  nobrorder: any
  mode?: string
  imgHeight?: any
  imgWidth?: any
}

const ProductThirteen: FC<ProductCardData> = (props) => {
  const [target, setTarget] = useState('_blank')
  useEffect(() => {
    if (window) {
      if (window.matchMedia('(display-mode: standalone)').matches) {
        setTarget('_self')
      }
      if (IsMobile()) {
        setTarget('_self')
      }
    }
  }, [])
  const link = {
    pathname: '/[slug]/p',
    query: {
      slug: props.data?.slug,
    },
  }
  let sellingprice = sellingPrice(props?.data?.prices[0]?.sp)
  let mrpprice = mrpPrice(props?.data?.mrp[0]?.price)

  return (
    <div className={` ${style.card}`}>
      <Link href={link} passHref target={target}>
        <div className={style.card_top}>
          <Img
            rWidth={{
              mobileweb: 150,
              web: 178,
            }}
            rHeight={{
              mobileweb: 150,
              web: 178,
            }}
            deviceType={props.props.mode}
            placeholder="blur"
            src={`${props.data.thumb ? props.data.thumb : props.data.image}`}
            alt={props?.data?.image?.alt}
            // layout="fill"
            // objectFit="contain"
            loader={() =>
              `${props.data.thumb ? props.data.thumb : props.data.image}`
            }
            blurDataURL={`${
              props.data.thumb ? props.data.thumb : props.data.image
            }`}
          />

          {props.data.rating > 0 && (
            <div className={style.star}>
              <Star color="#f46b27" type="FILL" />
              <span>{props.data.rating}</span>
            </div>
          )}
        </div>
        <div className={`${style.card__description}`}>
          <h3>{props.data.name}</h3>
          <h4>{props.data.option_value}</h4>
          <div className={style.lists}>
            {!!props.data?.attributeHighlights &&
              props.data?.attributeHighlights.map((item, i) => (
                <span className={style.attr_span} key={i}>
                  <span></span>
                  {item.name}{' '}
                </span>
              ))}
          </div>
        </div>

        {/* {Number(props.data.special_price) !== 0 && ( */}
        <div className={style.card_bottom}>
          <p>
            {sellingprice.length != 0
              ? PriceFormat(sellingprice[0]?.price)
              : PriceFormat(mrpprice[0]?.price)}
          </p>
          {Math.round(
            100 -
              (Number(sellingprice[0]?.price) / Number(mrpprice[0]?.price)) *
                100
          ) > 0 && (
            <span>
              {Math.round(
                100 -
                  (Number(sellingprice[0]?.price) /
                    Number(mrpprice[0]?.price)) *
                    100
              )}
              % OFF
            </span>
          )}
        </div>
        {/* )} */}
      </Link>
    </div>
  )
}

export default ProductThirteen
