import Link from 'next/link'
import { FC, useEffect, useLayoutEffect, useRef, useState } from 'react'
import Img from '~/components/ui/img/img'
import { PriceFormat, IsMobile } from '~/util'
import { FavouriteButton } from '../../favourite-button/favourite-button'
import { HomeProductCardListProps } from '../home-product-card/home-product-cardlist.interface'
import style from './productvertical.module.scss'
import { Star } from '../../icons/star'
import { mrpPrice, sellingPrice } from '~/util/price'

/**
 * product card renders Product card this will have fav icon and add to card button
 * @category Components
 * @subcategory ProductCardlist
 * @param {ProductCardListProps} props product card related data
 * @returns {JSX.Element} jsx value
 */
export const Verticalcard: FC<HomeProductCardListProps> = (props: any) => {
  const [target, setTarget] = useState('_blank')
  const ref = useRef(null)
  const link = {
    pathname: '/[slug]/p',
    query: {
      slug: props.data.slug,
    },
  }
  let sellingprice = sellingPrice(props?.data?.prices[0]?.sp)
  let mrpprice = mrpPrice(props?.data?.mrp[0]?.price)
  const loadDataOnlyOnce = () => {}
  useEffect(() => {
    if (window) {
      if (window.matchMedia('(display-mode: standalone)').matches) {
        setTarget('_self')
      }
      if (IsMobile()) {
        setTarget('_self')
      }
      loadDataOnlyOnce()
    }
    if (ref.current) {
      ref.current.style.color = '#3b3b3b'
      ref.current.style.fontSize = '11px'
      ref.current.style.fontWeight = '500'
      ref.current.style.paddingLeft = '8px'
    }
  }, [])
  return (
    <div className={style.imagess}>
      <div className={`${style.image_style}`}>
        <div>
          <Link
            href={link}
            passHref
            target={target}
            className={`m-auto ${style.imagess}`}
            style={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '140px',
              width: '160px',
              marginTop: '8px',
            }}
          >
            <Img
              rWidth={{
                mobileweb: 150,
                web: 300,
              }}
              rHeight={{
                mobileweb: 150,
                web: 300,
              }}
              deviceType={props.mode}
              src={`${
                props.data.thumb
                  ? props.data.thumb
                  : props.data.image
                  ? props.data.image
                  : 'https://img.poorvika.com/common/NoImageAvailable.jpg'
              }`}
              alt={props?.data?.image?.alt}
              blurDataURL={`${
                props.data.thumb
                  ? props.data.thumb
                  : props.data.image
                  ? props.data.image
                  : 'https://img.poorvika.com/common/NoImageAvailable.jpg'
              }`}
              placeholder="blur"
            />
          </Link>
        </div>
        <div>
          <div
            className={`${style.prop_rel} ${
              props.prodstyl == 'NONE' ? '' : `${style.layout_center}`
            }`}
          >
            <Link href={link} target={target}>
              <div>
                <h3>{props.data.name}</h3>
                <h4
                  style={{
                    visibility: `${
                      props.data.option_value ? 'visible' : 'hidden'
                    }`,
                  }}
                >
                  {props.data.option_value}
                </h4>

                <div
                  className={`${style.card_top} ${
                    props.prodstyl == 'NONE' ? '' : `${style.grid_price}`
                  }`}
                >
                  <div>
                    {!!props.data.rating && props.data.review_count > 0 && (
                      <p className="flex items-center">
                        <Star color="#f46b27" type="FILL" />
                        <b style={{ color: 'black' }}>{props.data.rating}</b>
                        <span className="ml-2">
                          ({props.data.review_count})
                        </span>
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className={`${style.card_bottom}`}>
                <h5>
                  {sellingprice[0]?.price != ''
                    ? PriceFormat(sellingprice[0]?.price)
                    : PriceFormat(mrpprice[0]?.price)}
                </h5>
                <p className={style.offer_price}>
                  {Math.round(
                    100 -
                      (Number(sellingprice[0]?.price) /
                        Number(mrpprice[0]?.price)) *
                        100
                  ) > 0 && (
                    <span>
                      {Math.round(
                        100 -
                          (Number(sellingprice[0]?.price) /
                            Number(mrpprice[0]?.price)) *
                            100
                      )}
                      % OFF
                    </span>
                  )}
                </p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
