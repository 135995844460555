import React, { useEffect, useState } from 'react'
import { FC } from 'react'
import style from './tabnavigation.module.scss'
import { EmiTabs } from '~/components/ui/emiTabs'
import Img from '~/components/ui/img/img'
import {
  HorizontalScrollType,
  HorizontalScrollWrapper,
} from '~/components/ui/horizontal-scroll-wrapper/horizontal-scroll-wrapper'
import { HomeProductCardlist } from '~/components/ui/cards/home-product-card/home-product-cardlist'
import Link from 'next/link'
import { Verticalcard } from '~/components/ui/cards/horizontal-vertical-card/productvertical'
import { HomeProductCard } from '~/components/ui/cards/horizontal-vertical-card/home-product-card'
import TabImageGroup from '../tabimagegroup'
import TabVideoGroup from '../tabvideogroup'

/**
 * @category Components
 * @subcategory TabProps
 * @param {TabProps} props object we get from hitting tab api
 * @returns {JSX.Element} JSX valu
 */
const TabNavigation: FC<any> = (props) => {
  const [tabData, setTabData] = useState([])
  const [selected, setSelected] = useState('')
  useEffect(() => {
    if (!!props.tabs) {
      setTabData(props.tabs)
      setSelected(
        props.tabs[0]?.tabheader_type == 'Text'
          ? props?.tabs[0]?.tabpaneltype?.tabheader?.text
          : props?.tabs[0]?.tabpaneltype?.tabheader?.image?.url
      )
    }
  }, [])
  const variantClassMap: any = {
    Youtube: 'https://www.youtube.com/embed/',
    Vimeo: 'https://player.vimeo.com/video/',
    Dailymotion: 'https://www.dailymotion.com/embed/video/',
  }
  const bannerLink = (element: any) => {
    switch (element.linktype) {
      case 'EXTERNAL':
        return `${element.comp?.link.url}`
      case 'PRODUCT':
        if (element.comp.product.type == 'VARIANT') {
          return {
            pathname: '/[slug]/p',
            query: {
              slug: element.comp.product.code,
            },
          }
        } else {
          return {
            pathname: `/s`,
            query: {
              categories_slug: `categories_slug:=[\`${element.comp.product.code}\`]`,
            },
          }
        }
      case 'TAG':
        if (element.comp.tag?.tagtype == 'PRODUCT') {
          return {
            pathname: `/s`,
            query: {
              categories: `tags:=[\`${element?.comp.tag?.code}\`]`,
            },
          }
        } else {
          return {
            pathname: `/s`,
            query: {
              categories: `tags:=[\`${element?.tag?.code}\`]`,
            },
          }
        }

      case 'PAGE':
        if (element.comp.page?.pg_type == 'GENERAL') {
          return {
            pathname: '/[slug]/page',
            query: {
              type: 'group',
              slug: element.comp.page?.code,
            },
          }
        } else if (element.comp.page?.pg_type == 'CATEGORY') {
          return {
            pathname: '/[slug]/page',
            query: {
              type: 'category',
              slug: element.comp.page.code,
            },
          }
        } else {
          return {
            pathname: '/[slug]/page',
            query: {
              type: 'brand',
              slug: element.comp.page?.code,
            },
          }
        }

      default:
        return `${element.slug}`
    }
  }
  const tabproduct = (product: any) => {
    if (product.items.length >= 1) {
      switch (product.mode[props.deviceMode].layout) {
        case 'NONE':
          return (
            <HorizontalScrollWrapper
              tabSelected={selected}
              type={HorizontalScrollType.NORMAL}
              products
              title={''}
              image={''}
              buttoncustom={true}
            >
              {product.items.length >= 1 &&
                product.items
                  ?.slice(0, product.pagination.limit)
                  .map((res: any, i: number) => (
                    <HomeProductCardlist
                      linkPrefix={`${String(res?.name).replace(/ /g, '-')}`}
                      imgHeight="140px"
                      showCart
                      data={res}
                      key={i}
                      onLike={() => {}}
                      mode={props?.deviceMode}
                    />
                  ))}
            </HorizontalScrollWrapper>
          )
        case 'MEDIUM':
          return (
            <HorizontalScrollWrapper
              tabSelected={selected}
              type={HorizontalScrollType.NORMAL}
              products
              title={''}
              image={''}
              buttoncustom={true}
            >
              <div className={`grid grid-cols-2 gap-1 m-2`}>
                {product.items.length >= 1 &&
                  product.items
                    ?.slice(0, 4)
                    .map((res: any, i: number) => (
                      <HomeProductCardlist
                        linkPrefix={`${String(res?.name).replace(/ /g, '-')}`}
                        imgHeight="140px"
                        showCart
                        data={res}
                        key={i}
                        onLike={() => {}}
                        mode={props?.deviceMode}
                      />
                    ))}
              </div>
            </HorizontalScrollWrapper>
          )
        case 'SMALL':
          return (
            <HorizontalScrollWrapper
              tabSelected={selected}
              type={HorizontalScrollType.NORMAL}
              products
              title={''}
              image={''}
              buttoncustom={true}
            >
              <div className={`grid grid-cols-3 gap-1 m-2`}>
                {product.items.length >= 1 &&
                  product.items
                    ?.slice(0, 9)
                    .map((res: any, i: number) => (
                      <HomeProductCardlist
                        linkPrefix={`${String(res?.name).replace(/ /g, '-')}`}
                        imgHeight="140px"
                        showCart
                        data={res}
                        key={i}
                        onLike={() => {}}
                        mode={props?.deviceMode}
                      />
                    ))}
              </div>
            </HorizontalScrollWrapper>
          )
        case 'MEDIUM_THREE':
          return (
            <HorizontalScrollWrapper
              tabSelected={selected}
              type={HorizontalScrollType.NORMAL}
              products
              title={''}
              image={''}
              buttoncustom={true}
            >
              <>
                <div className={`grid grid-cols-1 gap-1 m-2`}>
                  {product.items.length >= 1 &&
                    product.items
                      ?.slice(0, 1)
                      .map((res: any, i: number) => (
                        <HomeProductCardlist
                          linkPrefix={`${String(res?.name).replace(/ /g, '-')}`}
                          imgHeight="140px"
                          showCart
                          data={res}
                          key={i}
                          onLike={() => {}}
                          mode={props?.deviceMode}
                        />
                      ))}
                </div>
                <div className={`grid grid-cols-2 gap-1 m-2`}>
                  {product.items.length >= 1 &&
                    product.items
                      ?.slice(1, 3)
                      .map((res: any, i: number) => (
                        <HomeProductCardlist
                          linkPrefix={`${String(res?.name).replace(/ /g, '-')}`}
                          imgHeight="140px"
                          showCart
                          data={res}
                          key={i}
                          onLike={() => {}}
                          mode={props?.deviceMode}
                        />
                      ))}
                </div>
              </>
            </HorizontalScrollWrapper>
          )
        case 'VERTICAL_MEDIUMTHREE':
          return (
            <HorizontalScrollWrapper
              tabSelected={selected}
              type={HorizontalScrollType.NORMAL}
              products
              title={''}
              image={''}
              buttoncustom={true}
            >
              <div className="grid gap-1 grid-flow-col mb-5">
                <div className="grid place-items-center border border-gray-200 row-span-2 h-4/8">
                  {product.items.length >= 1 &&
                    product.items
                      ?.slice(0, 1)
                      .map((res: any, i: number) => (
                        <Verticalcard
                          linkPrefix={`${String(res?.name).replace(/ /g, '-')}`}
                          imgWidth="180px"
                          showCart
                          data={res}
                          key={i}
                          onLike={() => {}}
                          mode={props?.deviceMode}
                        />
                      ))}
                </div>
                <div className="col-span-2 border border-gray-200">
                  {product.items.length >= 1 &&
                    product.items
                      ?.slice(1, 2)
                      .map((res: any, i: number) => (
                        <Verticalcard
                          linkPrefix={`${String(res?.name).replace(/ /g, '-')}`}
                          imgWidth="180px"
                          showCart
                          data={res}
                          key={i}
                          onLike={() => {}}
                          mode={props?.deviceMode}
                        />
                      ))}
                </div>
                <div className="col-span-2 border border-gray-200">
                  {product.items.length >= 1 &&
                    product.items
                      ?.slice(2, 3)
                      .map((res: any, i: number) => (
                        <Verticalcard
                          linkPrefix={`${String(res?.name).replace(/ /g, '-')}`}
                          imgWidth="180px"
                          showCart
                          data={res}
                          key={i}
                          onLike={() => {}}
                          mode={props?.deviceMode}
                        />
                      ))}
                </div>
              </div>
            </HorizontalScrollWrapper>
          )
        case 'HORIZONTAL_MEDIUMTHREE':
          return (
            <HorizontalScrollWrapper
              tabSelected={selected}
              type={HorizontalScrollType.NORMAL}
              products
              title={''}
              image={''}
              buttoncustom={true}
            >
              <>
                <div className={`grid grid-cols-1 gap-1 mt-1`}>
                  {product.items.length >= 1 &&
                    product.items
                      ?.slice(0, 1)
                      .map((res: any, i: number) => (
                        <HomeProductCard
                          linkPrefix={`${String(res?.name).replace(/ /g, '-')}`}
                          imgHeight="140px"
                          showCart
                          data={res}
                          key={i}
                          onLike={() => {}}
                          mode={props?.deviceMode}
                        />
                      ))}
                </div>
                <div className={`grid grid-cols-3 gap-1 mt-1`}>
                  {product.items.length >= 1 &&
                    product.items
                      ?.slice(1, 4)
                      .map((res: any, i: number) => (
                        <HomeProductCardlist
                          linkPrefix={`${String(res?.name).replace(/ /g, '-')}`}
                          imgHeight="140px"
                          showCart
                          data={res}
                          key={i}
                          onLike={() => {}}
                          mode={props?.deviceMode}
                        />
                      ))}
                </div>
              </>
            </HorizontalScrollWrapper>
          )
        default:
          return ``
      }
    }
  }
  return (
    <div className={style.tab_component}>
      <div>
        {props.mode[props.deviceMode].isHeader == true &&
          props?.mode[props.deviceMode].header?.style?.textAlign ==
            'center' && (
            <p
              className={`flex mb-3 pb-1 pl-3`}
              style={{
                color: props?.mode[props.deviceMode].header?.style?.fontColor,
                fontSize: props?.mode[props.deviceMode].header?.style?.fontSize,
                fontWeight:
                  props?.mode[props.deviceMode].header?.style?.fontStyle
                    ?.isBold && 'bold',
                fontStyle:
                  props?.mode[props.deviceMode].header?.style?.fontStyle
                    ?.isItalic && 'italic',
                textDecoration:
                  props?.mode[props.deviceMode].header?.style?.fontStyle
                    ?.isUnderLine && 'underline',
                justifyContent:
                  props?.mode[props.deviceMode].header?.style.textAlign,
                borderBottom: '1px solid orange',
              }}
            >
              {props.mode[props.deviceMode].header.title}
            </p>
          )}
        <EmiTabs
          pimStyle={props.mode[props.deviceMode].styles?.primary.color}
          headertitle={props}
          header={tabData.map((ele: any) =>
            ele.tabheader_type == 'Text'
              ? ele.tabpaneltype.tabheader?.text
              : ele.tabpaneltype.tabheader?.image?.url
          )}
          changeHeader={(_val: string) => {
            setSelected(_val)
          }}
          selected={selected}
          variant={'DEFAULT'}
          type={props.mode[props.deviceMode].isVertical?.toUpperCase()}
          overflow={true}
          tabs={props?.tabs?.length <= 2}
        >
          {tabData.map((ele, i) => (
            <div key={i}>
              {ele.tabpaneltype.tabcontent?.video?.playerType === 'Video' ? (
                <div>
                  <video
                    className={style.home_iframe}
                    src={ele.tabpaneltype.tabcontent?.video?.url}
                    controls={true}
                    width="100%"
                    height="450px"
                  />
                </div>
              ) : ele.tabpaneltype.tabcontent?.video?.playerType != undefined &&
                ele.tabcontent_type.toLowerCase() == 'video' ? (
                <iframe
                  className={style.home_iframe}
                  src={`${
                    variantClassMap[
                      ele.tabpaneltype.tabcontent?.video?.playerType
                    ]
                  }${ele.tabpaneltype.tabcontent?.video?.videoID}?loop=${
                    ele.tabpaneltype.tabcontent?.video?.loop ? 1 : 0
                  }&controls=${
                    ele.tabpaneltype.tabcontent?.video?.controls ? 1 : 0
                  }&playlist=${ele.tabpaneltype?.tabcontent?.video?.videoID}`}
                  title="Video player"
                  width="100%"
                  height="450px"
                  allow="fullscreen; accelerometer; autoplay; loop; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                />
              ) : ele.tabcontent_type.toLowerCase() == 'product' ? (
                tabproduct(ele.tabpaneltype?.tabcontent.productListComp)
              ) : ele.tabcontent_type.toLowerCase() == 'imagelist' ? (
                <TabImageGroup props={ele} mode={props?.deviceMode} />
              ) : ele.tabcontent_type == 'Videolist' ? (
                <TabVideoGroup props={ele} mode={props?.deviceMode} />
              ) : (
                <>
                  {ele.tabpaneltype.tabcontent.imageComp.linktype != 'NONE' ? (
                    <Link
                      href={bannerLink(ele.tabpaneltype?.tabcontent?.imageComp)}
                      passHref
                      target={
                        ele.tabpaneltype?.tabcontent?.imageComp?.linktype ==
                          'EXTERNAL' &&
                        ele.tabpaneltype?.tabcontent?.imageComp?.comp?.link
                          ?.target
                      }
                    >
                      <Img
                        rWidth={{
                          mobileweb: 390,
                          web:
                            ele.tabpaneltype?.tabcontent?.imageComp?.image
                              ?.url == undefined
                              ? 500
                              : 1900,
                        }}
                        rHeight={{
                          mobileweb: 390,
                          web:
                            ele.tabpaneltype?.tabcontent?.imageComp?.image
                              ?.url == undefined
                              ? 500
                              : 1900,
                        }}
                        deviceType={props?.deviceMode}
                        src={
                          !!ele.tabpaneltype?.tabcontent?.imageComp?.image?.url
                            ? ele.tabpaneltype?.tabcontent?.imageComp?.image
                                ?.url
                            : 'https://img.poorvika.com/common/noimageavailable.png'
                        }
                        alt="Noimage"
                        blurDataURL={
                          !!ele.tabpaneltype?.tabcontent?.imageComp?.image?.url
                            ? ele.tabpaneltype?.tabcontent?.imageComp?.image
                                ?.url
                            : 'https://img.poorvika.com/common/noimageavailable.png'
                        }
                        placeholder="blur"
                        style={{ margin: 'auto' }}
                      />
                    </Link>
                  ) : (
                    <Img
                      rWidth={{
                        mobileweb: 390,
                        web:
                          ele.tabpaneltype?.tabcontent?.imageComp?.image?.url ==
                          undefined
                            ? 500
                            : 1900,
                      }}
                      rHeight={{
                        mobileweb: 390,
                        web:
                          ele.tabpaneltype?.tabcontent?.imageComp?.image?.url ==
                          undefined
                            ? 500
                            : 1900,
                      }}
                      deviceType={props?.deviceMode}
                      src={
                        !!ele.tabpaneltype?.tabcontent?.imageComp.image?.url
                          ? ele.tabpaneltype?.tabcontent?.imageComp?.image?.url
                          : 'https://img.poorvika.com/common/noimageavailable.png'
                      }
                      alt="Noimage"
                      loader={() =>
                        !!ele.tabpaneltype?.tabcontent?.imageComp.image?.url
                          ? ele.tabpaneltype?.tabcontent?.imageComp?.image?.url
                          : 'https://img.poorvika.com/common/noimageavailable.png'
                      }
                      blurDataURL={
                        !!ele.tabpaneltype?.tabcontent?.imageComp.image?.url
                          ? ele.tabpaneltype?.tabcontent?.imageComp?.image?.url
                          : 'https://img.poorvika.com/common/noimageavailable.png'
                      }
                      placeholder="blur"
                      style={{ margin: 'auto' }}
                    />
                  )}
                </>
              )}
            </div>
          ))}
        </EmiTabs>
      </div>
    </div>
  )
}

export default TabNavigation
