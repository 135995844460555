import Text from './Text'
import Image from './Image'
import productlist from './productlist'
import Video from './Video'
import Categorylist from './catergorylist'
import Brandlist from './brandlist'
import Videogrouplist from './videolist'
import PageNavigation from './page-navigation/page-navigation'
import TabNavigation from './tab/TabNavigation'
import Alllink from './Alllink'
import Carosuel from './carosuel'
import ImageGroup from './imagegroup'
import lotti from './lotti'
import review from './review'

const ElementsMap = {
  Image: Image,
  Text: Text,
  'ProductList Component': productlist,
  Video: Video,
  'Category List Component': Categorylist,
  'BrandList Component': Brandlist,
  Carousel: Carosuel,
  'Group Video Component': Videogrouplist,
  NavigationLink: PageNavigation,
  Tab: TabNavigation,
  Link: Alllink,
  'Group Image Component': ImageGroup,
  Lottie: lotti,
  Review: review,
}

export default ElementsMap
