export const PIMLayout = {
  NONE: () => [],
  MEDIUM: () => [
    { w: 6, h: 2, x: 0, y: 2, i: '0', moved: false, static: true },
    { w: 6, h: 2, x: 0, y: 0, i: '1', moved: false, static: true },
    { w: 6, h: 2, x: 6, y: 0, i: '2', moved: false, static: true },
    { w: 6, h: 2, x: 6, y: 2, i: '3', moved: false, static: true },
  ],
  MEDIUM_THREE: () => [
    { w: 4, h: 2, x: 4, y: 0, i: '0', moved: false, static: true },
    { w: 6, h: 2, x: 6, y: 3, i: '1', moved: false, static: true },
    { w: 5, h: 2, x: 0, y: 3, i: '2', moved: false, static: true },
  ],
  SMALL: () => [
    { w: 4, h: 2, x: 0, y: 0, i: '0', moved: false, static: true },
    { w: 4, h: 2, x: 4, y: 0, i: '1', moved: false, static: true },
    { w: 4, h: 2, x: 8, y: 0, i: '2', moved: false, static: true },
    { w: 4, h: 2, x: 0, y: 3, i: '3', moved: false, static: true },
    { w: 4, h: 2, x: 4, y: 3, i: '4', moved: false, static: true },
    { w: 4, h: 2, x: 8, y: 3, i: '5', moved: false, static: true },
    { w: 4, h: 2, x: 8, y: 6, i: '6', moved: false, static: true },
    { w: 4, h: 2, x: 4, y: 6, i: '7', moved: false, static: true },
    { w: 4, h: 2, x: 0, y: 6, i: '8', moved: false, static: true },
  ],
}

export const GridWidthCalculator = (
  parentElementWidth: React.MutableRefObject<HTMLDivElement>
): number => {
  return parentElementWidth.current.offsetWidth
}
