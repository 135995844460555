// @ts-ignore
// @ts-nocheck
import head from 'next/head'
import { FC } from 'react'
import { useRef } from 'react'
import Slider from 'react-slick'
import style from './brandautoplay-scroll-wrapper.module.scss'

interface BrandautoplayScrollWrapperProps {
  /**
   * array of children
   */
  children: any[]
  /**
   * title for the scrollbar  j
   */
  headerHtml?: any
}
const BrandNext: FC<any> = (props) => {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        background: '#F4F4F4',
        zIndex: '1',
        width: '25px',
        height: '40px',
        lineHeight: '63px',
        position: 'absolute',
        borderRadius: '5px',
      }}
      onClick={onClick}
    />
  )
}

const BrandPrev: FC<any> = (props) => {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        background: '#F4F4F4',
        zIndex: '1',
        width: '25px',
        height: '40px',
        lineHeight: '63px',
        position: 'absolute',
        borderRadius: '5px',
      }}
      onClick={onClick}
    />
  )
}
export const BrandautoplayScrollWrapper: FC<BrandautoplayScrollWrapperProps> = ({
  children,
  headerHtml,
}) => {
  const slider = useRef(null)
  const brand = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: headerHtml == 'none' ? 14 : 5,
    slidesToScroll: headerHtml == 'none' ? 14 : 1,
    autoplay: false,
    arrows: true,
    nextArrow: <BrandNext />,
    prevArrow: <BrandPrev />,
    responsive: [
      {
        breakpoint: 2000,
        settings: {
          slidesToShow:
            headerHtml == 'none'
              ? children.length > 14
                ? 14
                : children.length
              : 7,
          slidesToScroll:
            headerHtml == 'none'
              ? children.length > 14
                ? 14
                : children.length
              : 5,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow:
            headerHtml == 'none'
              ? children.length > 14
                ? 14
                : children.length
              : 7,
          slidesToScroll:
            headerHtml == 'none'
              ? children.length > 14
                ? 14
                : children.length
              : 5,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow:
            headerHtml == 'none'
              ? children.length > 12
                ? 12
                : children.length
              : 7,
          slidesToScroll:
            headerHtml == 'none'
              ? children.length > 12
                ? 12
                : children.length
              : 4,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1080,
        settings: {
          slidesToShow:
            headerHtml == 'none'
              ? children.length > 10
                ? 10
                : children.length
              : 6,
          slidesToScroll:
            headerHtml == 'none'
              ? children.length > 10
                ? 10
                : children.length
              : 4,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow:
            headerHtml == 'none'
              ? children.length > 7
                ? 7
                : children.length
              : 6,
          slidesToScroll:
            headerHtml == 'none'
              ? children.length > 7
                ? 7
                : children.length
              : 3,
          initialSlide: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 536,
        settings: {
          slidesToShow:
            headerHtml == 'none'
              ? children.length > 5
                ? 5
                : children.length
              : 3,
          slidesToScroll:
            headerHtml == 'none'
              ? children.length > 5
                ? 5
                : children.length
              : 3,
          arrows: false,
        },
      },
    ],
  }

  return (
    <div
      className={`${headerHtml == 'none' ? style.brandlogo : style.brandlogo1}`}
    >
      {!!!headerHtml ? headerHtml : ''}
      <Slider {...brand} ref={slider} aria-hidden="false">
        {children}
      </Slider>
    </div>
  )
}
export default BrandautoplayScrollWrapper
