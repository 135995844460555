import Link from 'next/link'
import React from 'react'

const Alllink = ({ text, link, page, product, linktype, url }: any) => {
  const Lnk = (props: any) => {
    const { children } = props
    return linktype == 'NONE' ? (
      <>{children}</>
    ) : (
      <Link
        href={url}
        passHref
        target={props?.target}
        className={props.className}
      >
        {children}
      </Link>
    )
  }
  return (
    <p className={`p-1 pt-0 `}>
      <Lnk {...link} {...page} {...product}>
        <span>{text}</span>
      </Lnk>
    </p>
  )
}
export default Alllink
