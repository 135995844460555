import Link from 'next/link'
import React, { FC, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { IsMobile, Logger, PriceFormat } from '~/util'
import { FavouriteButton } from '../../favourite-button/favourite-button'
import Img from '../../img/img'
import style from './producteighteen.module.scss'
import { Star } from '../../icons/star'
import { mrpPrice, sellingPrice } from '~/util/price'

type ProductCardData = {
  props: any
  data: any
  pimStyle: string
  nobrorder: any
  mode?: string
  imgHeight?: any
  imgWidth?: any
}

const ProductEighteen: FC<ProductCardData> = (props) => {
  const [target, setTarget] = useState('_blank')
  useEffect(() => {
    if (window) {
      if (window.matchMedia('(display-mode: standalone)').matches) {
        setTarget('_self')
      }
      if (IsMobile()) {
        setTarget('_self')
      }
    }
  }, [])
  const link = {
    pathname: '/[slug]/p',
    query: {
      slug: props.data?.slug,
    },
  }
  const compare = {
    pathname: '/[slug]/p',
    query: {
      slug: props.data?.slug,
    },
  }
  let special_price = props.data.special_price
  let price = props.data.price
  const saveAmt = price - special_price
  let sellingprice = sellingPrice(props?.data?.prices[0]?.sp)
  let mrpprice = mrpPrice(props?.data?.mrp[0]?.price)
  return (
    <div className={` ${style.card} `}>
      <span className={style.fav_btn_pos}>
        <FavouriteButton
          pimStyle={props.pimStyle}
          item_code={props.data.item_code}
          slug={props.data.code}
        />
      </span>

      <Link href={link} passHref target={target}>
        <Img
          rWidth={{
            mobileweb: 140,
            web: 240,
          }}
          rHeight={{
            mobileweb: 140,
            web: 240,
          }}
          deviceType={props.props.mode}
          placeholder="blur"
          className={`${style.image}`}
          src={`${props.data.thumb ? props.data.thumb : props.data.image}`}
          alt={props?.data?.image?.alt}
          // layout="fill"
          // objectFit="contain"
          loader={() =>
            `${props.data.thumb ? props.data.thumb : props.data.image}`
          }
          blurDataURL={`${
            props.data.thumb ? props.data.thumb : props.data.image
          }`}
        />

        <div className={style.card_bottom}>
          <div className={`${style.card__description} `}>
            <h3>{props.data.name}</h3>
            <h4>{props.data.option_value}</h4>
            <div className="flex items-center justify-between">
              <p>
                {sellingprice?.length != 0
                  ? PriceFormat(sellingprice[0]?.price)
                  : PriceFormat(mrpprice[0]?.price)}
              </p>
              {
                // Number(props.data.special_price) !== 0 &&
                Math.round(
                  100 -
                    (Number(sellingprice[0]?.price) /
                      Number(mrpprice[0]?.price)) *
                      100
                ) > 0 && (
                  <span className={style.offer}>
                    {Math.round(
                      100 -
                        (Number(sellingprice[0]?.price) /
                          Number(mrpprice[0]?.price)) *
                          100
                    )}
                    % OFF
                  </span>
                )
              }
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default ProductEighteen
