import router from 'next/router'
import Img from '~/components/ui/img/img'
import { InnerHtml } from '~/components/ui/inner-html/inner-html'
import { VideoCardProps } from './video-card.interface'
import style from './video-card.module.scss'

/**
 * this components is used to display youtube video related to the product
 * @category Components
 * @subcategory VideoCard
 * @param {VideoCardProps} props object we get from hitting videos api
 * @returns {JSX.Element} JSX valu
 */
export const VideoCards = (props: VideoCardProps) => {
  const imageVariantMap: any = {
    Youtube: `https://img.youtube.com/vi/${props.data.data?.videoID}/0.jpg`,
    Vimeo: `https://vumbnail.com/${props.data.data?.videoID}.jpg`,
    Dailymotion: `https://www.dailymotion.com/thumbnail/video/${props.data.data?.videoID}`,
    Video: props.thumbnailUrl,
  }

  const OnBackClick = () => {
    router.back()
  }

  // useEffect(() => {
  //   window.addEventListener('keyup', (e) => {
  //     if (e.code == 'Escape') {
  //       OnBackClick()
  //     }
  //   })
  //   return () => {
  //     window.removeEventListener('keyup', (_e) => {})
  //   }
  // }, [])
  return (
    <div className={style.card}>
      <div className={style.card__video}>
        <div
          className={`${style.card__video__thumbnail} ref_id`}
          style={{
            backgroundImage: `url(${
              imageVariantMap[props.data.data?.playerType]
            })`,
          }}
        />
        <button
          onClick={() => props.play()}
          className={style.play_icon_video}
        ></button>
      </div>
      {props.data.description && (
        <div className={style.card__description}>
          <div className={style.card__description__text}>
            <b>
              <InnerHtml data={props.data.description} />
            </b>
          </div>
        </div>
      )}
    </div>
  )
}
