import Link from 'next/link'
import { FC, useEffect, useLayoutEffect, useRef, useState } from 'react'
import Img from '~/components/ui/img/img'
import { PriceFormat, IsMobile } from '~/util'
import { FavouriteButton } from '../../favourite-button/favourite-button'
import { ImageGroupProductCardListProps } from './imagegroup-product-cardlist.interface'
import style from './imagegroup-product-cardlist.module.scss'
import { Star } from '../../icons/star'

/**
 * product card renders Product card this will have fav icon and add to card button
 * @category Components
 * @subcategory ProductCardlist
 * @param {ImageGroupProductCardListProps} props product card related data
 * @returns {JSX.Element} jsx value
 */
export const ImageGroupProductCardlist: FC<ImageGroupProductCardListProps> = (
  props: any
) => {
  const imagelink = (element: any) => {
    switch (element.document.linktype) {
      case 'EXTERNAL':
        return `${element.document.link?.url}`
      case 'PRODUCT':
        if (element.document.product?.pd_type == 'VARIANT') {
          return {
            pathname: '/[slug]/p',
            query: {
              slug: element.document.product?.code,
            },
          }
        } else {
          return {
            pathname: `/s`,
            query: {
              categories_slug: `categories_slug:=[\`${element?.document.product?.code}\`]`,
            },
          }
        }
      case 'TAG':
        if (element.document.tag?.tagtype == 'PRODUCT') {
          return {
            pathname: `/s`,
            query: {
              categories: `tags:=[\`${element?.document?.tag?.code}\`]`,
            },
          }
        } else {
          return {
            pathname: `/s`,
            query: {
              categories: `tags:=[\`${element?.document?.tag?.code}\`]`,
            },
          }
        }

      case 'PAGE':
        if (element.document.page.pg_type == 'GENERAL') {
          return {
            pathname: '/[slug]/page',
            query: {
              type: 'group',
              slug: element.document.page.code,
            },
          }
        } else if (element.document.page.pg_type == 'CATEGORY') {
          return {
            pathname: '/[slug]/page',
            query: {
              type: 'category',
              slug: element.document.page.code,
            },
          }
        } else {
          return {
            pathname: '/[slug]/page',
            query: {
              type: 'brand',
              slug: element.document.page.code,
            },
          }
        }

      default:
        return `${element.slug}`
    }
  }

  return (
    <div>
      {props.data.document.linktype != 'NONE' ? (
        <>
          <Link
            href={imagelink(props.data)}
            target={props?.data?.document?.link?.target}
            style={{
              width: '100%',
              height: !!props.imgHeight ? props.imgHeight : '100%',
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '15px 0px',
            }}
          >
            <Img
              rWidth={{
                mobileweb: 150,
                web: 150,
              }}
              rHeight={{
                mobileweb: 150,
                web: 150,
              }}
              deviceType={props.mode}
              src={`${
                props?.data.document?.attr?.url
                  ? props?.data.document?.attr?.url
                  : 'https://img.poorvika.com/common/NoImageAvailable.jpg'
              }`}
              alt={props?.data.document?.attr?.alt}
              blurDataURL={`${
                props?.data.document?.attr?.url
                  ? props?.data.document?.attr?.url
                  : 'https://img.poorvika.com/common/NoImageAvailable.jpg'
              }`}
              placeholder="blur"
              style={{ margin: 'auto' }}
            />
          </Link>
          <div className={`${style.prop_rel}`}>
            <div className={style.pos_abs_desc}>
              {props.data.document.title}
            </div>
            <Link
              href={imagelink(props.data)}
              target={props?.data?.document?.link?.target}
            >
              <div>
                <h3
                  style={{
                    display: `${
                      props.title.mode[props.mode]?.isTitlesubtitle == false &&
                      'none'
                    }`,
                    color: props.title.mode[props.mode]?.title?.fontColor,
                    fontSize: props.title.mode[props.mode]?.title?.fontSize,
                    fontWeight:
                      props.title.mode[props.mode]?.title?.fontStyle?.isBold &&
                      'bold',
                    fontStyle:
                      props.title.mode[props.mode]?.title?.fontStyle
                        ?.isItalic && 'italic',
                    textDecoration:
                      props.title.mode[props.mode]?.title?.fontStyle
                        ?.isUnderLine && 'underline',
                    textAlign: 'center',
                  }}
                >
                  {props.data.document.title}
                </h3>
                <h4
                  style={{
                    display: `${
                      props.title.mode[props.mode]?.isTitlesubtitle == false &&
                      'none'
                    }`,
                    color: props.title.mode[props.mode]?.subtitle?.fontColor,
                    fontSize: props.title.mode[props.mode]?.subtitle?.fontSize,
                    fontWeight:
                      props.title.mode[props.mode]?.subtitle?.fontStyle
                        ?.isBold && 'bold',
                    fontStyle:
                      props.title.mode[props.mode]?.subtitle?.fontStyle
                        ?.isItalic && 'italic',
                    textDecoration:
                      props.title.mode[props.mode]?.subtitle?.fontStyle
                        ?.isUnderLine && 'underline',
                    textAlign: 'center',
                  }}
                >
                  {props.data.document.subTitle}
                </h4>
              </div>
            </Link>
          </div>
        </>
      ) : (
        <>
          <Img
            rWidth={{
              mobileweb: 150,
              web: 150,
            }}
            rHeight={{
              mobileweb: 150,
              web: 150,
            }}
            deviceType={props.mode}
            src={`${
              props?.data.document?.attr?.url
                ? props?.data.document?.attr?.url
                : 'https://img.poorvika.com/common/NoImageAvailable.jpg'
            }`}
            alt={props?.data.document?.attr?.alt}
            blurDataURL={`${
              props?.data.document?.attr?.url
                ? props?.data.document?.attr?.url
                : 'https://img.poorvika.com/common/NoImageAvailable.jpg'
            }`}
            placeholder="blur"
            style={{ margin: 'auto' }}
          />
          <div className={`${style.prop_rel}`}>
            <div className={style.pos_abs_desc}>
              {props.data.document.title}
            </div>
            <div>
              <h3
                style={{
                  display: `${
                    props.title.mode[props.mode]?.isTitlesubtitle == false &&
                    'none'
                  }`,
                  color: props.title.mode[props.mode]?.title?.fontColor,
                  fontSize: props.title.mode[props.mode]?.title?.fontSize,
                  fontWeight:
                    props.title.mode[props.mode]?.title?.fontStyle?.isBold &&
                    'bold',
                  fontStyle:
                    props.title.mode[props.mode]?.title?.fontStyle?.isItalic &&
                    'italic',
                  textDecoration:
                    props.title.mode[props.mode]?.title?.fontStyle
                      ?.isUnderLine && 'underline',
                  textAlign: 'center',
                }}
              >
                {props.data.document.title}
              </h3>
              <h4
                style={{
                  display: `${
                    props.title.mode[props.mode]?.isTitlesubtitle == false &&
                    'none'
                  }`,
                  color: props.title.mode[props.mode]?.subtitle?.fontColor,
                  fontSize: props.title.mode[props.mode]?.subtitle?.fontSize,
                  fontWeight:
                    props.title.mode[props.mode]?.subtitle?.fontStyle?.isBold &&
                    'bold',
                  fontStyle:
                    props.title.mode[props.mode]?.subtitle?.fontStyle
                      ?.isItalic && 'italic',
                  textDecoration:
                    props.title.mode[props.mode]?.subtitle?.fontStyle
                      ?.isUnderLine && 'underline',
                  textAlign: 'center',
                }}
              >
                {props.data.document.subTitle}
              </h4>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
